import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  isLoading: boolean = false;

  constructor() {}

  showLoadingSpinner(){
    this.isLoading = true;
  }

  hideLoadingSpinner(){
    this.isLoading = false;
  }
}
