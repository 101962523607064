import { Component, OnInit } from '@angular/core';
import { PaymentMethod } from "src/app/models/payment-method.model";
import { TransactionService } from "src/app/services/Transaction/transaction.service";
import { Transaction } from "src/app/models/transaction.model";
import { ZoidHelperService } from "src/app/services/ZoidHelper/zoid-helper.service";
import { LoaderService } from "src/app/services/Loader/loader.service";
import { CustomerService } from "src/app/services/Customer/customer.service";
import {
  DialogComponent,
  DialogData,
  DialogType,
} from "src/app/components/dialog/dialog.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: 'app-member-stop-recurring-payment',
  templateUrl: './member-stop-recurring-payment.component.html',
  styleUrls: ['./member-stop-recurring-payment.component.scss']
})
export class MemberStopRecurringPaymentComponent implements OnInit {
  public isDone = false;
  public error = false;
  public infoText: string;
  public submitButtonName = "Cancel Recurring Payments";
  public remittanceAccountValue$;
  public remittanceAccountId$;
  constructor(
    private transactionService: TransactionService,
    private zoidHelperService: ZoidHelperService,
    private loaderService: LoaderService,
    private customerService: CustomerService,
    public dialog: MatDialog,
  ) {
      this.remittanceAccountId$ = this.zoidHelperService.remmitanceAccountId$;
      this.remittanceAccountValue$ = this.zoidHelperService.remmitanceAccountValue$;
  }

  ngOnInit() {
    this.infoText = "You're about to cancel your payment";
    this.getRecurringPayment();
  }

  getRecurringPayment(){
    if(!this.transactionService.isEditingTransaction){
      this.transactionService
      .checkRecurringPayments(this.customerService.customer.getValue().customerID,this.remittanceAccountValue$.getValue(),1)
      .subscribe(
        (data) => this.transactionService.editingTransaction.next(data)
      )
    } else {
      console.log(this.transactionService.editingTransaction.getValue());
    }
  }

  openConfirmationDialog(){
    let dialogData = new DialogData();
    dialogData.message = "Are you really sure you want to cancel your recurring payment";
    dialogData.type = DialogType.Confirmation;
    let dialogRef = this.dialog.open(DialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "true") {
        this.transactionService.editingTransaction.subscribe(
          t => {
            console.log(t);
            this.cancelRecurringPayment(t);
          },
          err => console.log(err)
        )
      }
    });
  }

  cancelRecurringPayment(rpTransaction: Transaction){
    this.transactionService.cancelRecurringPayment(rpTransaction)
    .subscribe(
      data => {
        this.loaderService.hideLoadingSpinner();
        this.infoText = "Your recurring payment has been successfully cancelled. This window will now close.";
        this.zoidHelperService.onPaymentReceivedFunc(data);
        setTimeout(() => {
          this.zoidHelperService.closeFunc();
        }, 2000);
      },
      (err) => {
        console.log("callbackerror");
        this.loaderService.hideLoadingSpinner();
        this.error = true;
      }
    )
  }
}
