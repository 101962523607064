import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import * as valid from "card-validator";
import { ValidCard, Card } from "src/app/models/valid-card.model";
import {
  FormBuilder,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormGroup,
} from "@angular/forms";
import { PaymentService } from "src/app/services/Payment/payment.service";
import {
  PaymentMethod,
  PaymentMethodType,
} from "src/app/models/payment-method.model";
import { AddressService } from "src/app/services/Address/address.service";
import { Address } from "src/app/models/address.model";
import { Observable, of, BehaviorSubject, defer } from "rxjs";
import { AddAddressComponent } from "../../components/add-address/add-address.component";
import { CreditCardValidators, CreditCard } from "angular-cc-library";
import { AddressUtils } from "src/app/CommonModule/addressUtils";
import { PaymentUtils } from "src/app/CommonModule/paymentUtils";
import { map } from "rxjs/operators";
import { AuthenticationService } from 'src/app/services/Auth/authentication.service';
@Component({
  selector: "app-credit-card",
  templateUrl: "./credit-card.component.html",
  styleUrls: ["./credit-card.component.css"],
})
export class CreditCardComponent implements OnInit {
  card: Card;
  public addPaymentMethodForm: FormGroup;
  public addresses: BehaviorSubject<Address[]>;
  public addAddress: boolean = false;
  // creditCardMask;
  public type$ = defer(
    () => this.addPaymentMethodForm.controls.cardNumber.valueChanges
  ).pipe(map((num: string) => CreditCard.cardType(num)));

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) {
    // this.creditCardMask = "0000 0000 0000 0000";
    this.addPaymentMethodForm = this.formBuilder.group({
      nameOnAccount: new FormControl("", [Validators.required]),
      cardNumber: new FormControl("", [
        CreditCardValidators.validateCCNumber,
        this.validateCreditCardTypes,
      ]),
      cardExp: new FormControl("", [
        CreditCardValidators.validateExpDate,
        Validators.maxLength(7),
      ]),
      cvv: new FormControl("", [
        this.validateCvvRequired(() => true, Validators.required),
        Validators.maxLength(3),
      ]),
    });
  }

  ngOnInit() {}

  checkCcValidation(cardNumber) {
    var numberValidation = valid.number(cardNumber);
    if (numberValidation.card) {
      this.card = new Card().deserialize(numberValidation);
    } else {
      this.card = null;
    }
  }

  getCreditCard() {
    let paymentMethod;
    this.addPaymentMethodForm.markAllAsTouched();
    // this.addressEvent.emit(address);
    if (this.addPaymentMethodForm.valid) {
      paymentMethod = new PaymentMethod().deserialize(
        this.addPaymentMethodForm.value
      );
      paymentMethod.type = PaymentMethodType.Credit_Card;
      paymentMethod.cardNumber = PaymentUtils.removeAllSpaces(
        paymentMethod.cardNumber
      );
      paymentMethod.cardExp = PaymentUtils.removeAllSpaces(
        paymentMethod.cardExp
      );
      return paymentMethod;
    } else {
      return null;
    }
  }

  removeAllSpaces(str: string): string {
    return str.replace(/\s/g, "");
  }

  validateCreditCardTypes(control: AbstractControl) {
    if (control.value) {
      let cardType = CreditCard.cardFromNumber(control.value);
      if (cardType !== undefined) {
        if (cardType.type !== "visa" && cardType.type !== "discover" && cardType.type !== "mastercard") {
          return { cardType: true };
        }
      }
    }

    return null;
  }

  validateCvvRequired(predicate, validator) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return validator(formControl);
      }
      return null;
    })
  }
}
