import { Deserializable } from "./deserializable.model";

export class Address implements Deserializable {
  public customerId: string;
  public addressId: string;
  public name: string;
  public line1: string;
  public line2: string;
  public city: string;
  public state: string;
  public zip: string;
  public phone: string;
  public addressSource: string;
  public createDate: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
