import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PaymentTypes } from "src/app/models/transaction.model";

@Injectable({
  providedIn: "root",
})
export class ZoidHelperService {
  public paymentTotal$ = new BehaviorSubject<number>(0);
  public selectedTotal$: BehaviorSubject<number> = new BehaviorSubject(0);
  public paymentType$: BehaviorSubject<PaymentTypes> = new BehaviorSubject(
    PaymentTypes.OneTimePayment
  );
  public remmitanceAccountId$: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public remmitanceAccountValue$: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public programValue$: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public policyValue$: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public hixSaml$: BehaviorSubject<any> = new BehaviorSubject(null)
  public selectedBox$: BehaviorSubject<number> = new BehaviorSubject(1);
  public onPaymentReceivedFunc = (param) => {};
  public closeFunc = () => {
    window.close();
  };

  constructor() {}
}
