import { Deserializable } from "./deserializable.model";

export class HixTransaction implements Deserializable {
  public fullName: string;
  public customerId: string;
  public transactionId: string;
  public recurringPaymentId: string;
  public applicationConfirmationNumber: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
