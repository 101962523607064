import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject, of } from "rxjs";
import { Address } from "src/app/models/address.model";
import { map, catchError, mergeMap } from "rxjs/operators";
import { CustomerService } from "../Customer/customer.service";

@Injectable({
  providedIn: "root",
})
export class AddressService {
  public selectedAddress: BehaviorSubject<Address> = new BehaviorSubject<
    Address
  >(new Address());
  public addresses: BehaviorSubject<Address[]> = new BehaviorSubject<Address[]>(
    []
  );
  constructor(
    private http: HttpClient,
    private customerService: CustomerService
  ) {
    // this.getDefaultAddress().subscribe((data) =>
    //   this.selectedAddress.next(data)
    // );
  }

  getAddresses(): Observable<Address[]> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .get<Address[]>(`/api/customer/${data.customerID}/address`)
            .pipe(
              map((data) =>
                data.map((data) => new Address().deserialize(data))
              ),
              catchError(() => throwError("Addresses not found"))
            );
        } else {
          return throwError("Customer not found");
        }
      })
    );
    return this.http.get<Address[]>("/api/address").pipe(
      map((data) => data.map((data) => new Address().deserialize(data))),
      catchError(() => throwError("Addresses not found"))
    );
  }

  getAllAddresses(): Observable<Address[]> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .get<Address[]>(`/api/customer/${data.customerID}/address/all`)
            .pipe(
              map((data) =>
                data.map((data) => new Address().deserialize(data))
              ),
              catchError(() => throwError("Addresses not found"))
            );
        } else {
          return throwError("Customer not found");
        }
      })
    );
  }

  getDefaultAddress(): Observable<Address> {
    return this.http.get<Address>("/api/address/default").pipe(
      map((data) => new Address().deserialize(data)),
      catchError(() => throwError("Unable to fetch default address"))
    );
  }

  addAddress(address: Address, source: string): Observable<Address> {
    let data = {
      ...address,
      source: source,
    };
    return this.http
      .post<Address>("/api/address", data)
      .pipe(catchError(() => throwError("Unable to add new address")));
  }

  deleteAddress(addressId: string) {
    return this.http.delete(`/api/address/${addressId}`).pipe(
      map((response) => console.log()),
      catchError(() => throwError("Address not deleted"))
    );
  }

  setDefaultAddress(addressId: string): Observable<boolean> {
    let body = JSON.stringify(addressId);
    let headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return this.http.patch(`/api/address/default`, body, headers).pipe(
      map((response) => true),
      catchError(() => throwError("Unable to set default address"))
    );
  }
}
