import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[numberOnly]",
})
export class NumberOnlyDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "-"];

  constructor(private el: ElementRef) {}
  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.specialKeys.indexOf(event.key) !== -1 ||
      (event.key === "a" && event.ctrlKey === true) || // Allow: Ctrl+A
      (event.key === "c" && event.ctrlKey === true) || // Allow: Ctrl+C
      (event.key === "v" && event.ctrlKey === true) || // Allow: Ctrl+V
      (event.key === "x" && event.ctrlKey === true) || // Allow: Ctrl+X
      (event.key === "a" && event.metaKey === true) || // Cmd+A (Mac)
      (event.key === "c" && event.metaKey === true) || // Cmd+C (Mac)
      (event.key === "v" && event.metaKey === true) || // Cmd+V (Mac)
      (event.key === "x" && event.metaKey === true) // Cmd+X (Mac)
    ) {
      return; // let it happen, don't do anything
    }
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
