import { Deserializable } from "./deserializable.model";

export class Customer implements Deserializable {
  public fullName: string;
  public email: string;
  public type: CustomerType;
  public customerID: string;
  public remittanceAccounts: any[];
  public program: string
  constructor() {
    this.type = CustomerType.Member
  }
  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export class NewCustomer extends Customer {
  public arAccountID: string;
  public memID: string;
  public remittanceAccountType: number;
  public remittanceAccountValue: string;
}

export class NewCustomerWithConfirmationNum extends Customer {
  public confirmationNumber: string;
}

export enum CustomerType {
  Member = 1,
  Group = 2,
}
