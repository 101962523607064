import { Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject, of, Subject } from "rxjs";
import { Transaction } from "src/app/models/transaction.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError, mergeMap } from "rxjs/operators";
import { CustomerService } from "../Customer/customer.service";
import { OtpCustomer } from "src/app/models/otpCustomer.model";
import { HixCustomer } from "src/app/models/hixCustomer.model";
import { OpenPaymentCustomer } from "../../models/open-payment-customer.model";
import { ExistingPaymentCheckObj } from "src/app/pages/hix-redirect/hix-redirect.component";

@Injectable({
  providedIn: "root",
})
export class TransactionService {

  isEditingTransaction : boolean = false;
  editingTransaction: BehaviorSubject<Transaction> = new BehaviorSubject<Transaction>(null);

  constructor(
    private http: HttpClient,
    private customerService: CustomerService,

  ) {}

  submitTransaction(transaction: Transaction): Observable<Transaction> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .post<Transaction>(
              `/api/customer/${data.customerID}/transaction`,
              transaction
            )
            .pipe(
              map((data) => new Transaction().deserialize(data)),
              catchError((err) => throwError(err))
            );
        } else {
          return throwError("No Customer found");
        }
      })
    );
    return this.http.post<Transaction>("/api/transaction", transaction).pipe(
      map((data) => new Transaction().deserialize(data)),
      catchError((err) => throwError(err))
    );
  }

  submitOtp(otpCustomer: OtpCustomer): Observable<Transaction> {
    return this.http
      .post<Transaction>(`/api/customer/submitOtp`, otpCustomer)
      .pipe(
        map((data) => new Transaction().deserialize(data)),
        catchError((err) => throwError(err))
      );
  }

  submitHix(hixCustomer: HixCustomer): Observable<Transaction> {
    console.log("hc: ",hixCustomer)
    return this.http
      .post<Transaction>(`/api/Hix/submitHixRedirect`, hixCustomer)
      .pipe(
        map((data) => new Transaction().deserialize(data)),
        catchError((err) => throwError(err))
      );
  }

  submitOpenPayment(opCustomer: OpenPaymentCustomer): Observable<Transaction> {
    console.log("hc: ",opCustomer)
    return this.http
      .post<Transaction>(`/api/Hix/openPayment`, opCustomer)
      .pipe(
        map((data) => new Transaction().deserialize(data)),
        catchError((err) => throwError(err))
      );
  }

  getRecurringPayment(recurringPaymentId: string, customerId: string): Observable<Transaction> {
    let params = new HttpParams();
    params = params.set('recurringPaymentId', recurringPaymentId);
    return this.http
      .get<Transaction>(
        `/api/customer/${customerId}/getRecurringPaymentById/?${params.toString()}`
      )
      .pipe(
        map((data) => new Transaction().deserialize(data)),
        catchError((err) => throwError(err))
      );
  } 

  editRecurringPayment(transaction: Transaction): Observable<Transaction> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .put<Transaction>(
              `/api/customer/${data.customerID}/transaction/recurringPayment`,
              transaction
            )
            .pipe(
              map((data) => new Transaction().deserialize(data)),
              catchError((err) => throwError(err))
            );
        } else {
          return throwError("No Customer found");
        }
      })
    );
  }

  checkRecurringPayments(customerId: string, accountId: string, accountType: number): Observable<Transaction> {
    return this.http
      .get<Transaction>(
        `/api/customer/${customerId}/recurringPaymentsWidget?customerId=${customerId}&accountId=${accountId}&accountType=${accountType}`,
      )
      .pipe(
        map((data) => new Transaction().deserialize(data)),
        catchError((err) => throwError(err))
      );

  }

  cancelRecurringPayment(transaction: Transaction): Observable<Transaction> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .delete<Transaction>(
              `/api/customer/${data.customerID}/recurringPaymentsWidget/${transaction.recurringPaymentId}`
            )
            .pipe(
              map((data) => new Transaction().deserialize(data)),
              catchError((err) => throwError(err))
            );
        } else {
          return throwError("No Customer found");
        }
      })
    );
  }

  checkExists(existingPaymentObj: ExistingPaymentCheckObj){
    // console.log("check exists: "+subscriberId + " "+exchangeId)
    return this.http
      .post(`/api/Hix/checkExisting`, existingPaymentObj)
      .pipe(
        catchError((err) => throwError(err))
      );
  }
}
