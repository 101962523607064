import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { Auth0Service } from "../services/Auth/auth0.service";
import { tap, catchError, shareReplay } from "rxjs/operators";
import { AuthenticationService } from "../services/Auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class WindowsAuthGuard implements CanActivate {
  constructor(
    private auth0: Auth0Service,
    private auth: AuthenticationService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.auth.checkWindowsAuth().pipe(
      tap((loggedIn) => {
        if (loggedIn) {
          console.log(loggedIn);
          return true
        }
        else console.log("/error");
      })
    );

    // .pipe(
    //   tap((loggedIn) => {
    //     console.log("Logged in ", loggedIn);

    //     if (loggedIn) return true;
    //     else console.log("/error");
    //   })
    // );
    // return this.auth.loggedIn.pipe(
    //   tap((loggedIn) => console.log("loggedin ", loggedIn))
    // );
    // if (this.auth.loggedIn.getValue()) {
    //   console.log("Allowed");

    //   return true;
    // } else {
    //   console.log("not allowred");
    //   return false;
    // }
    // return this.auth.checkWindowsAuth().pipe(
    //   // tap(loggedIn => {
    //   //   console.log("WIndows auth guard " + loggedIn);
    //   //   if (loggedIn) {
    //   //     return true;
    //   //   }
    //   // }),
    //   catchError(err => throwError(err))
    // );
    // return true;
    // return this.auth.loggedIn.pipe(
    //   tap(loggedIn => {
    //     console.log("USer logged in: " + loggedIn);
    //   })
    // );
    // return true;
    // return this.auth.checkWindowsAuth().pipe(
    //   tap
    // )

    // return this.auth0.isAuthenticated$.pipe(
    //   tap(loggedIn => {
    //     if (!loggedIn) {
    //       this.auth0.login(state.url);
    //     }
    //   })
    // );
  }
}
