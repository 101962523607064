import { Component, OnInit, ViewChild } from "@angular/core";
import { EmbeddableWidget } from "@acpaas-ui/ngx-embeddable-widgets";
import { ZoidHelperService } from "src/app/services/ZoidHelper/zoid-helper.service";
import { Address } from "src/app/models/address.model";
import { AddressService } from "src/app/services/Address/address.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CreditCardComponent } from "../credit-card/credit-card.component";
import { AddAddressComponent } from "src/app/components/add-address/add-address.component";
import { PaymentMethod } from "src/app/models/payment-method.model";
import { OtpCustomer } from "src/app/models/otpCustomer.model";
import { Customer, CustomerType } from "src/app/models/customer.model";
import { CustomerService } from "src/app/services/Customer/customer.service";
import { TransactionService } from "src/app/services/Transaction/transaction.service";
import { PaymentTypes } from "src/app/models/transaction.model";
import { OtpTransaction } from "src/app/models/otpTransaction.model";
import {
  DialogData,
  DialogType,
  DialogComponent,
} from "src/app/components/dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { LoaderService } from "src/app/services/Loader/loader.service";
import { Observable, BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";
import { PaymentService } from "src/app/services/Payment/payment.service";

@Component({
  selector: "app-one-time-payment",
  templateUrl: "./one-time-payment.component.html",
  styleUrls: ["./one-time-payment.component.scss"],
})
@EmbeddableWidget("../../assets/widget.json")
export class OneTimePaymentComponent implements OnInit {
  public remittanceAccountId$;
  public remittanceAccountValue$;
  public remittanceAccountType$;
  
  isLoading: boolean = false;
  isDone: boolean = false;
  error: boolean = false;
  otpCustomer: OtpCustomer = new OtpCustomer();
  paymentTotal$;
  paymentType$;

  @ViewChild(AddAddressComponent, { static: false }) addressChild;
  @ViewChild(CreditCardComponent, { static: false }) creditCardChild;
  constructor(
    public dialog: MatDialog,
    private zoidHelperService: ZoidHelperService,
    private addressService: AddressService,
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private transactionService: TransactionService,
    private paymentService: PaymentService,
    private loaderService: LoaderService,
  ) {
    this.paymentTotal$ = this.zoidHelperService.paymentTotal$;
    this.otpCustomer.customer = new Customer();
    this.otpCustomer.paymentMethod = new PaymentMethod();
    this.paymentType$ = this.zoidHelperService.paymentType$;
    this.otpCustomer.googleRecaptchaResponse = null;
    this.remittanceAccountId$ = this.zoidHelperService.remmitanceAccountId$;
    this.remittanceAccountValue$ = this.zoidHelperService.remmitanceAccountValue$;
  }

  ngOnInit() {}

  public auiOnWidgetInit(props) {
    if (props) {
      // this.name = props.name;
      this.zoidHelperService.paymentTotal$.next(props.price);
      this.zoidHelperService.onPaymentReceivedFunc = props.onPaymentReceived;
      this.zoidHelperService.closeFunc = props.close;

      if (!isNaN(props.paymentType)) {
        this.zoidHelperService.paymentType$.next(props.paymentType);
      } else {
        this.zoidHelperService.paymentType$.next(PaymentTypes.OneTimePayment);
      }
      if (props.otpUserData) {
        console.log(props.otpUserData)
        let customer: Customer = new Customer();
        customer.fullName = props.otpUserData.fullName;
        customer.email = props.otpUserData.email;
        customer.type = CustomerType.Member;
        customer.program = props.otpUserData.program
        this.customerService.customer.next(customer);
        this.otpCustomer.customer = customer;
        this.otpCustomer.remittanceAccount = String(
          props.otpUserData.applicationConfirmationNumber
        );
        this.otpCustomer.paymentTotal = props.price;
      } else {
        this.router.navigateByUrl("/error");
      }
      if (props.address) {
        let passedAddress = new Address().deserialize(props.address);
        passedAddress.name = props.otpUserData.fullname + "\'s Address";
        this.addressService.addresses.next([passedAddress]);
      }

      // this.onPaymentReceived = props.onPaymentReceived;
    } else if(this.router.url.split("?")[0] === "/hix-redirect") {
      console.log("here")
      this.route.queryParams.subscribe((queryParams) => {
        if (queryParams.CustomerId) {
          console.log("here2")
          let customer: Customer = new Customer();
          customer.fullName = queryParams.fullName;
          customer.email = queryParams.email;
          customer.type = CustomerType.Member;
          this.customerService.customer.next(customer);
          this.otpCustomer.customer = customer;
          this.otpCustomer.remittanceAccount = queryParams.RemittanceAccountValue;
          this.otpCustomer.paymentTotal = queryParams.price;
        } else {
          console.log("else")
          // this.router.navigateByUrl("/error");
        }

        if (queryParams.RemittanceAccountId) {
          this.remittanceAccountId$.next(queryParams.RemittanceAccountId);
        }

        if (!isNaN(queryParams.PaymentType) && queryParams.PaymentType == PaymentTypes.RecurringPayment.toString()) {
          this.loaderService.showLoadingSpinner();
          this.zoidHelperService.paymentType$.next(PaymentTypes.RecurringPayment);
          this.loaderService.hideLoadingSpinner();
        }

        if (queryParams.price) {
          this.paymentTotal$.next(parseFloat(queryParams.price));
        }

      });
    }
  }
  openRecaptchaAlert() {
    let dialogData = new DialogData();
    dialogData.message =
      "Please complete reCaptcha challange before submitting!";
    dialogData.type = DialogType.Alert;
    let dialogRef = this.dialog.open(DialogComponent, {
      data: dialogData,
    });
  }
  resolved(captchaResponse: string, res) {
    this.otpCustomer.googleRecaptchaResponse = captchaResponse;
  }
  onSubmit() {
    let address = this.addressChild.getAddress();
    let paymentMethod = this.creditCardChild.getCreditCard();
    if (address instanceof Address && paymentMethod instanceof PaymentMethod) {
      if (this.otpCustomer.googleRecaptchaResponse === null) {
        this.openRecaptchaAlert();
        return;
      }
      this.isLoading = true;
      paymentMethod.address = address;
      this.otpCustomer.paymentMethod = paymentMethod;
      this.otpCustomer.paymentType = this.zoidHelperService.paymentType$.value;
      this.transactionService.submitOtp(this.otpCustomer).subscribe(
        (data) => {
          if (
            this.zoidHelperService.paymentType$.value ===
            PaymentTypes.RecurringPayment
          ) {
            let otpTransaction = new OtpTransaction();
            otpTransaction.fullName = this.otpCustomer.customer.fullName;
            otpTransaction.customerId = data.customerId;
            otpTransaction.recurringPaymentId = data.recurringPaymentId;
            otpTransaction.applicationConfirmationNumber = this.otpCustomer.remittanceAccount;
            this.zoidHelperService.onPaymentReceivedFunc(otpTransaction);
          } else {
            this.zoidHelperService.onPaymentReceivedFunc(data.transactionId);
          }
          this.isLoading = false;
          this.isDone = true;
          setTimeout(() => {
            this.zoidHelperService.closeFunc();
          }, 3000);
        },
        (err) => {
          this.isLoading = false;
          this.error = true;
        }
      );
    }
  }

  closeWindow() {
    this.zoidHelperService.closeFunc();
  }
}
