import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Auth0Service } from "../Auth/auth0.service";
import { Observable, throwError } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { AuthenticationService } from "../Auth/authentication.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(private auth0: Auth0Service, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = this.router.url.split("/");
    if (url[1] === "one-time-payment") {
      return next.handle(req);
    } else if (this.auth0.loggedIn) {
      return this.auth0.getTokenSilently$().pipe(
        mergeMap((token) => {
          const tokenReq = req.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });
          return next.handle(tokenReq);
        }),
        catchError((err) => throwError(err))
      );
    } else {
      const tokenReq = req.clone({
        withCredentials: true,
      });
      return next.handle(tokenReq);
    }

    // return next.handle(req);
  }
}
