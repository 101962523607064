import { AfterContentChecked, AfterContentInit, Component, OnInit } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { PaymentMethod, PaymentMethodType } from "src/app/models/payment-method.model";
import { PaymentService } from "src/app/services/Payment/payment.service";
import {
  DialogComponent,
  DialogData,
  DialogType,
} from "src/app/components/dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { AddressService } from "src/app/services/Address/address.service";
import { LoaderService } from "src/app/services/Loader/loader.service";

@Component({
  selector: "app-select-payment",
  templateUrl: "./select-payment.component.html",
  styleUrls: ["./select-payment.component.css"],
})
export class SelectPaymentComponent implements OnInit, AfterContentInit {
  public paymentMethods$: Observable<PaymentMethod[]>;
  public selectedPaymentMethod: PaymentMethod;
  public isLoading = true;
  loadingError$ = new BehaviorSubject<boolean>(false);
  PaymentMethodType = PaymentMethodType;
  public canUseACH: boolean;
  public canUseCreditCard: boolean;
  constructor(
    public paymentService: PaymentService,
    private adderssService: AddressService,
    public dialog: MatDialog,
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.paymentMethods$ = this.paymentService.paymentMethods;
    this.paymentService.selectedPaymentMethod.subscribe(
      (data) => (this.selectedPaymentMethod = data)
    );
    this.getPaymentMethods();

  }
  ngAfterContentInit(): void {

  }

  ngOnInit() {
    this.canUseACH = this.paymentService.canUseACH.getValue();
    this.canUseCreditCard = this.paymentService.canUseCreditCard.getValue();
  }

  // selectPaymentMethod(paymentMethodId: string) {
  //   // this.customerService
  //   //   .getPaymentMethodById(paymentMethodId)
  //   //   .pipe(pm => this.customerService.selectedPaymentMethod.next(pm));
  // }

  openRemoveDialog(paymentMethodId: string) {
    let dialogData = new DialogData();
    dialogData.message = "This payment method will be removed.";
    dialogData.type = DialogType.Confirmation;
    let dialogRef = this.dialog.open(DialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "true") {
        this.paymentService
          .deletePaymentMethod(paymentMethodId)
          .subscribe((res) => {
            this.paymentService.getPaymentMethods().subscribe((pms) => {
              this.paymentService.paymentMethodsSubject.next(pms);
            });
            this.adderssService.addresses.next([]);
          });
      }
    });
  }

  selectPaymentMethod(paymentMethod: PaymentMethod) {
    // console.log(paymentMethod);
    this.paymentService.selectedPaymentMethod.next(paymentMethod);
    this.popScreen();
    // this.paymentService
    //   .setDefaultPaymentMethod(paymentMethod.paymentMethodId)
    // .subscribe(data => this.router.navigate(["/", "portal-payment"]));
  }

  popScreen() {
    let url = this.router.url.split("/");
    url.pop();
    this.router.navigate(url);
  }

  getPaymentMethods(){
    this.isLoading = true;
    this.paymentService
    .getPaymentMethods()
    .subscribe(
      (pms) => {
        this.paymentService.paymentMethodsSubject.next(pms);
      },
      (err) => {
        this.loadingError$.next(true);
      }
    )
  }

  editPaymentMethod(paymentMethod : PaymentMethod){
    this.router.navigate([this.router.url + '/edit'], {
      state: {
        data: {
          paymentMethod: paymentMethod
        }
      }
    });
  }
}
