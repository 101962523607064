import { Component, OnInit, ViewChild } from "@angular/core";
import { EmbeddableWidget } from "@acpaas-ui/ngx-embeddable-widgets";
import { ZoidHelperService } from "src/app/services/ZoidHelper/zoid-helper.service";
import { Address } from "src/app/models/address.model";
import { AddressService } from "src/app/services/Address/address.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CreditCardComponent } from "../credit-card/credit-card.component";
import { AddAddressComponent } from "src/app/components/add-address/add-address.component";
import { PaymentMethod } from "src/app/models/payment-method.model";
import { HixCustomer } from "src/app/models/hixCustomer.model";
import { Customer, CustomerType } from "src/app/models/customer.model";
import { CustomerService } from "src/app/services/Customer/customer.service";
import { TransactionService } from "src/app/services/Transaction/transaction.service";
import { PaymentTypes } from "src/app/models/transaction.model";
import { HixTransaction } from "src/app/models/hixTransaction.model";
import {
  DialogData,
  DialogType,
  DialogComponent,
} from "src/app/components/dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { LoaderService } from "src/app/services/Loader/loader.service";
import { Observable, BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";
import { PaymentService } from "src/app/services/Payment/payment.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-hix-redirect",
  templateUrl: "./hix-redirect.component.html",
  styleUrls: ["./hix-redirect.component.scss"],
})
@EmbeddableWidget("../../assets/widget.json")
export class HixRedirectComponent implements OnInit {
  public remittanceAccountId$;
  public remittanceAccountValue$;
  public remittanceAccountType$;
  public demoForm;
  isLoading: boolean = false;
  isDone: boolean = false;
  recordExists: boolean = false;
  error: boolean = false;
  hixCustomer: HixCustomer;
  paymentTotal$;
  paymentType$;
  public phoneMask="(000) 000-000"
  @ViewChild(AddAddressComponent, { static: false }) addressChild;
  @ViewChild(CreditCardComponent, { static: false }) creditCardChild;
  constructor(
    public dialog: MatDialog,
    private zoidHelperService: ZoidHelperService,
    private addressService: AddressService,
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private transactionService: TransactionService,
    private paymentService: PaymentService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
  ) {

    this.hixCustomer = new HixCustomer();
    this.paymentTotal$ = this.zoidHelperService.paymentTotal$;
    this.hixCustomer.customer = new Customer();
    this.hixCustomer.paymentMethod = new PaymentMethod();
    this.paymentType$ = this.zoidHelperService.paymentType$;
    this.hixCustomer.googleRecaptchaResponse = null;
    this.remittanceAccountId$ = this.zoidHelperService.remmitanceAccountId$;
    this.remittanceAccountValue$ = this.zoidHelperService.remmitanceAccountValue$;

  }

  ngOnInit() {
    this.demoForm = this.formBuilder.group({
      dob: new FormControl("", [Validators.required]),
      ssn: new FormControl("", [ Validators.minLength(9),Validators.maxLength(9)]),
      phone: new FormControl("", [Validators.minLength(12),Validators.maxLength(12),Validators.pattern("[0-9]+-[0-9]+-[0-9]+")]),
    });
  }

  public auiOnWidgetInit(props) {
    console.log("record exists: ",this.recordExists)
    if(this.router.url.split("?")[0] === "/hix-redirect") {
      console.log("here")
      this.route.queryParams.subscribe((queryParams) => {
        if (queryParams.CustomerId) {
          console.log("here2")
          let customer: Customer = new Customer();
          customer.fullName = queryParams.fullName;
          customer.email = queryParams.email;
          customer.type = CustomerType.Member;
          customer.customerID = queryParams.CustomerId;
          this.customerService.customer.next(customer);
          this.hixCustomer.customer = customer;
          this.hixCustomer.remittanceAccount = queryParams.remittanceAccountValue;
          this.hixCustomer.paymentTotal = queryParams.price;
          this.hixCustomer.exchangeId = queryParams.exchangeId;
          this.hixCustomer.fname = queryParams.fname;
          this.hixCustomer.lname = queryParams.lname;
          this.hixCustomer.remittanceGuid = queryParams.remittanceGuid;
          this.hixCustomer.confirmationNumber = queryParams.confirmationNumber;
          this.hixCustomer.subscriberId = queryParams.subscriberId;
          this.checkExists(this.hixCustomer.subscriberId, this.hixCustomer.exchangeId)
        } else {
          console.log("else")
          // this.router.navigateByUrl("/error");
        }

        if (queryParams.RemittanceAccountId) {
          this.remittanceAccountId$.next(queryParams.RemittanceAccountId);
        }

        if (!isNaN(queryParams.PaymentType) && queryParams.PaymentType == PaymentTypes.RecurringPayment.toString()) {
          this.loaderService.showLoadingSpinner();
          this.zoidHelperService.paymentType$.next(PaymentTypes.RecurringPayment);
          this.loaderService.hideLoadingSpinner();
        }

        if (queryParams.price) {
          this.paymentTotal$.next(parseFloat(queryParams.price));
        }

      });
    }
  }
  openRecaptchaAlert() {
    let dialogData = new DialogData();
    dialogData.message =
      "Please complete reCaptcha challange before submitting!";
    dialogData.type = DialogType.Alert;
    let dialogRef = this.dialog.open(DialogComponent, {
      data: dialogData,
    });
  }
  resolved(captchaResponse: string, res) {
    this.hixCustomer.googleRecaptchaResponse = captchaResponse;
  }
  onSubmit() {
    let address = this.addressChild.getAddress();
    let paymentMethod = this.creditCardChild.getCreditCard();
    this.hixCustomer.SSN = this.demoForm.get('ssn').value
    this.hixCustomer.DOB = this.demoForm.get('dob').value
    this.hixCustomer.phone = this.demoForm.get('phone').value
    console.log("phon: ",this.hixCustomer.phone);
    console.log("phonics: ",this.hixCustomer.phone.replace(/-/ig,""));
    this.hixCustomer.phone = this.hixCustomer.phone.replace(/-/ig,"")
    if (address instanceof Address && paymentMethod instanceof PaymentMethod) {
      if (this.hixCustomer.googleRecaptchaResponse === null) {
        this.openRecaptchaAlert();
        return;
      }
      this.loaderService.showLoadingSpinner();
      paymentMethod.address = address;
      this.hixCustomer.customer.fullName = paymentMethod.nameOnAccount;
      this.hixCustomer.paymentMethod = paymentMethod;
      this.hixCustomer.paymentType = this.zoidHelperService.paymentType$.value;
      this.transactionService.submitHix(this.hixCustomer).subscribe(
        (data) => {
          if (
            this.zoidHelperService.paymentType$.value ===
            PaymentTypes.RecurringPayment
          ) {
            let hixTransaction = new HixTransaction();
            hixTransaction.fullName = this.hixCustomer.customer.fullName;
            hixTransaction.customerId = data.customerId;
            hixTransaction.recurringPaymentId = data.recurringPaymentId;
            hixTransaction.applicationConfirmationNumber = this.hixCustomer.remittanceAccount;
            this.zoidHelperService.onPaymentReceivedFunc(hixTransaction);
          } else {
            this.zoidHelperService.onPaymentReceivedFunc(data.transactionId);
          }
          this.loaderService.hideLoadingSpinner();
          this.isDone = true;
          setTimeout(() => {
            this.zoidHelperService.closeFunc();
          }, 3000);
        },
        (err) => {
          this.isLoading = false;
          this.error = true;
        }
      );
    }
  }

  checkExists(subscriberId: string, exchangeId: string){
    var existingPaymentObj = new ExistingPaymentCheckObj(subscriberId, exchangeId)
    this.transactionService.checkExists(existingPaymentObj).subscribe(answer => {
      if(answer){
        console.log("check existing: ",answer)
        if(answer === true){
          
          this.recordExists = true
        } else {
          this.recordExists = false
        }
      }
    })
  }

  closeWindow() {
    this.zoidHelperService.closeFunc();
  }
}

export class ExistingPaymentCheckObj{
  subscriberIdentifier: string
  exchangeId: string
  constructor(subId: string, exchId: string){
    this.subscriberIdentifier = subId
    this.exchangeId = exchId
  }
}