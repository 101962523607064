import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import * as valid from "card-validator";
import { ValidCard, Card } from "src/app/models/valid-card.model";
import {
  FormBuilder,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { PaymentService } from "src/app/services/Payment/payment.service";
import {
  PaymentMethod,
  PaymentMethodType,
} from "src/app/models/payment-method.model";
import { AddressService } from "src/app/services/Address/address.service";
import { Address } from "src/app/models/address.model";
import { Observable, of, BehaviorSubject } from "rxjs";
import { AddAddressComponent } from "../../components/add-address/add-address.component";
import { CreditCardValidators } from "angular-cc-library";
import { AddressUtils } from "src/app/CommonModule/addressUtils";
import { PaymentUtils } from "src/app/CommonModule/paymentUtils";
import { CreditCardComponent } from "../credit-card/credit-card.component";
import { CustomerService } from "src/app/services/Customer/customer.service";
import {
  DialogComponent,
  DialogData,
  DialogType,
} from "src/app/components/dialog/dialog.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-add-payment",
  templateUrl: "./add-payment.component.html",
  styleUrls: ["./add-payment.component.css"],
})
export class AddPaymentComponent implements OnInit {
  card: Card;
  addPaymentMethodForm;
  public addresses: BehaviorSubject<Address[]>;
  public addAddress: boolean = false;
  // creditCardMask;

  @ViewChild(AddAddressComponent, { static: false }) addressChild;
  @ViewChild(CreditCardComponent, { static: false }) creditCardChild;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private paymentService: PaymentService,
    private addressService: AddressService,
    private customerService: CustomerService,
    private dialog: MatDialog
  ) {
    this.addresses = this.addressService.addresses;
  }

  ngOnInit() {}

  checkCcValidation(cardNumber) {
    var numberValidation = valid.number(cardNumber);
    if (numberValidation.card) {
      this.card = new Card().deserialize(numberValidation);
    } else {
      this.card = null;
    }
  }

  onSubmit() {
    let address = this.addressChild.getAddress();
    let paymentMethod = this.creditCardChild.getCreditCard();
    if (address instanceof Address && paymentMethod instanceof PaymentMethod) {
      paymentMethod.customerId = this.customerService.customer.value.customerID;
      paymentMethod.address = address;

      if (paymentMethod !== null && address !== null) {
        this.paymentService.submitPaymentMethod(paymentMethod).subscribe(
          (data) => this.popScreen(),
          (err) => {
            let dialogData = new DialogData();
            dialogData.message = err;
            dialogData.type = DialogType.Alert;
            let dialogRef = this.dialog.open(DialogComponent, {
              data: dialogData,
            });
          }
        );
      }
    }
  }

  toggleAddAddress() {
    this.addAddress = !this.addAddress;
  }

  removeAllSpaces(str: string): string {
    return str.replace(/\s/g, "");
  }

  popScreen(event?) {
    if(event) {
      event.preventDefault();
    }
    let url = this.router.url.split("/");
    url.pop();
    this.router.navigate(url);
  }
}
