import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import {
  Customer,
  NewCustomer,
  NewCustomerWithConfirmationNum,
} from "src/app/models/customer.model";
import { map, catchError, tap, mergeMap } from "rxjs/operators";
import { PaymentMethod } from "src/app/models/payment-method.model";
import { ZoidHelperService } from "src/app/services/ZoidHelper/zoid-helper.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  public customer: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(
    new Customer()
  );
  public programRules: BehaviorSubject<any>;
  public draftDays$: BehaviorSubject<any[]>;
  public draftDays: any[];
  public selectedDraftDay: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public remittanceAccountValue$;
  constructor(private http: HttpClient, private zoidHelperService: ZoidHelperService) {
    // this.getCustomer().subscribe((data) => this.customer.next(data));
    this.remittanceAccountValue$ = this.zoidHelperService.remmitanceAccountValue$;
    this.programRules = new BehaviorSubject<any>(null);
  }

  getCustomer(): Observable<Customer> {
    return this.http.get<Customer>("/api/customer").pipe(
      map((data) => new Customer().deserialize(data)),
      catchError(() => throwError("Customer not found"))
    );
  }

  getCustomerById(customerId: string): Observable<Customer> {
    return this.http.get<Customer>(`/api/customer/${customerId}`).pipe(
      map((data) => new Customer().deserialize(data)),
      catchError(() => throwError("Customer not found"))
    );
  }

  getCustomerByIdAndRemittance(customerId: string, ra: string): Observable<Customer> {
    return this.http.get<Customer>(`/api/customer/${customerId}/${ra}`).pipe(
      map((data) => new Customer().deserialize(data)),
      catchError(() => throwError("Customer not found"))
    );
  }

  createCustomer(data: NewCustomer) {
    return this.http.post<Customer>("/api/customer", data).pipe(
      map((data) => new Customer().deserialize(data)),
      catchError(() => {
        return throwError("Customer not created");
      })
    );
  }

  getProgram(myCustomer: Customer, ra: string) {
    console.log("in draft days");
    // var ra = this.zoidHelperService.remittanceAccountId$.getValue();
    return this.http.get<any>(`/api/customer/getProgramRules/${myCustomer.customerID}/${ra}`).pipe(
      map((data) => {
        console.log("draft days: ",data);
        return data;
      }),
      catchError(() => {
        return throwError("Customer not created");
      })
    );
  }
  getProgramByCustomerId(myCustomer: string, ra: string) {
    console.log("getProgramByCustomerId");
    // var ra = this.zoidHelperService.remittanceAccountId$.getValue();
    return this.http.get<any>(`/api/customer/getProgramRules/${myCustomer}/${ra}`).pipe(
      map((data) => {
        console.log("draft days: ",data);
        return data;
      }),
      catchError(() => {
        return throwError("Customer not created");
      })
    );
  }
}
