import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "../app.component";
import { CheckoutComponent } from "../pages/checkout/checkout.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { ProfileComponent } from "../components/profile/profile.component";
import { AuthGuard } from "../AuthGuard/auth.guard";
import { HomeComponent } from "../pages/home/home.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "../services/Interceptor/interceptor.service";
import { SelectPaymentComponent } from "../pages/select-payment/select-payment.component";
import { AddPaymentComponent } from "../pages/add-payment/add-payment.component";
import { SelectAddressComponent } from "../pages/select-address/select-address.component";
import { AddAddressComponent } from "../components/add-address/add-address.component";
import { LogoutComponent } from "../pages/logout/logout.component";
import { PaymentReviewComponent } from "../pages/payment-review/payment-review.component";
import { WindowsAuthGuard } from "../AuthGuard/windowsauth.guard";
import { ErrorComponent } from "../pages/error/error.component";
import { AddAchComponent } from "../pages/add-ach/add-ach.component";
import { CreditCardComponent } from "../pages/credit-card/credit-card.component";
import { OneTimePaymentComponent } from "../pages/one-time-payment/one-time-payment.component";
import { EditPaymentComponent } from "../pages/edit-payment/edit-payment.component";
import { StopRecurringPaymentComponent } from "../pages/stop-recurring-payment/stop-recurring-payment.component";
import { MemberStopRecurringPaymentComponent } from '../pages/member-stop-recurring-payment/member-stop-recurring-payment.component';
import { HixRedirectComponent } from "../pages/hix-redirect/hix-redirect.component";
import { OpenPaymentComponent } from "../pages/open-payment/open-payment.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    data: { animation: "noAnimationForward" },
  },
  {
    path: "portal-payment",
    component: CheckoutComponent,
    canActivate: [AuthGuard],
    data: { animation: "isLeft" },
  },
  {
    path: "portal-payment/paymentmethods",
    component: SelectPaymentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "portal-payment/paymentmethods/newcc",
    component: AddPaymentComponent,
    canActivate: [AuthGuard],
    data: { animation: "isRight" },
  },
  {
    path: "portal-payment/paymentmethods/newach",
    component: AddAchComponent,
    canActivate: [AuthGuard],
    data: { animation: "isRight" },
  },
  {
    path: "portal-payment/paymentmethods/edit",
    component: EditPaymentComponent,
    canActivate: [AuthGuard],
    data: { animation: "isRight" },
  },
  {
    path: "portal-payment/review",
    component: PaymentReviewComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "one-time-payment",
    component: OneTimePaymentComponent,
    data: { animation: "isLeft" },
  },
  {
    path: "one-time-payment/paymentmethods",
    component: SelectPaymentComponent,
  },
  {
    path: "one-time-payment/paymentmethods/newcc",
    component: CreditCardComponent,
    data: { animation: "isRight" },
  },
  {
    path: "one-time-payment/paymentmethods/newach",
    component: AddAchComponent,
    data: { animation: "isRight" },
  },
  {
    path: "one-time-payment/review",
    component: PaymentReviewComponent,
  },
  { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: "admin-payment",
    component: CheckoutComponent,
    canActivate: [WindowsAuthGuard],
    data: { animation: "isLeft" },
  },
  {
    path: "admin-payment/paymentmethods",
    component: SelectPaymentComponent,
    canActivate: [WindowsAuthGuard],
  },
  {
    path: "admin-payment/paymentmethods/newcc",
    component: AddPaymentComponent,
    canActivate: [WindowsAuthGuard],
    data: { animation: "isRight" },
  },
  {
    path: "admin-payment/paymentmethods/newach",
    component: AddAchComponent,
    canActivate: [WindowsAuthGuard],
    data: { animation: "isRight" },
  },
  {
    path: "admin-payment/paymentmethods/edit",
    component: EditPaymentComponent,
    canActivate: [WindowsAuthGuard],
    data: { animation: "isRight" },
  },
  { path: "logout", component: LogoutComponent },

  {
    path: "admin-payment/review",
    component: PaymentReviewComponent,
    canActivate: [WindowsAuthGuard],
  },
  {
    path: "admin-payment/stop-recurring",
    component: StopRecurringPaymentComponent,
    canActivate: [WindowsAuthGuard],
     data: { animation: "isRight" },
  },
  {
    path: "payment/stop-recurring",
    component: MemberStopRecurringPaymentComponent,
    canActivate: [AuthGuard],
     data: { animation: "isRight" },
  },
  {
    path: "hix-redirect",
    component: HixRedirectComponent,
    // canActivate: []
    data: { animation: "isLeft"}
  },
  {
    path: "open-payment",
    component: OpenPaymentComponent,
    // canActivate: []
    data: { animation: "isLeft"}
  },
  {
    path: "error",
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
