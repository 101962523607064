import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import "rxjs/Rx";
import { Observable, throwError, BehaviorSubject, of } from "rxjs";
import { PaymentView } from "../../models/payment-view";
import { PaymentMethod } from "src/app/models/payment-method.model";
import { map, catchError, tap, mergeMap } from "rxjs/operators";
import { CustomerService } from "../Customer/customer.service";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  public selectedPaymentMethod = new BehaviorSubject<PaymentMethod>(
    new PaymentMethod()
  );
  public paymentMethodsSubject: BehaviorSubject<PaymentMethod[]> = new BehaviorSubject<
    PaymentMethod[]
  >(null);

  public canUseACH: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public canUseCreditCard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private customerService: CustomerService
  ) {
    this.getDefaultPaymentMethod().subscribe((data) => {
      if (data != null) this.selectedPaymentMethod.next(data);
    });
  }

  get paymentMethods(): Observable<PaymentMethod[]> {
    return this.paymentMethodsSubject.asObservable();
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .get<PaymentMethod[]>(`/api/customer/${data.customerID}/payment`)
            .pipe(
              map((data) =>
                data.map((data) => new PaymentMethod().deserialize(data))
              ),
              catchError(() => throwError("Payment Methods not found"))
            );
        } else {
          return throwError("No Customer id found");
        }
      })
    );
  }

  // setDefaultPaymentMethod() :

  getDefaultPaymentMethod(): Observable<PaymentMethod> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .get<PaymentMethod>(
              `/api/customer/${data.customerID}/payment/default`
            )
            .pipe(
              map((data) => new PaymentMethod().deserialize(data)),
              catchError(() =>
                throwError("Unable to fetch default payment method")
              )
            );
        } else {
          return of(null);
        }
      })
    );

    return this.http.get<PaymentMethod>("/api/payment/default").pipe(
      map((data) => new PaymentMethod().deserialize(data)),
      catchError(() => throwError("Unable to fetch default payment method"))
    );
  }

  getPaymentMethodById(paymentMethodId: string) {
    return this.getPaymentMethods().pipe(
      map((data) =>
        data.filter((pm) => pm.paymentMethodId === paymentMethodId).pop()
      )
    );
  }

  submitPaymentMethod(
    paymentMethodData: PaymentMethod
  ): Observable<PaymentMethod> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .post<PaymentMethod>(
              `/api/customer/${data.customerID}/payment`,
              paymentMethodData
            )
            .pipe(
              catchError((err) => {
                return throwError(err.error);
              })
            );
        } else {
          return throwError("Customer not found");
        }
      })
    );
  }

  updatePaymentMethod(
    paymentMethodData: PaymentMethod
  ): Observable<PaymentMethod> {
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .patch<PaymentMethod>(
              `/api/customer/${data.customerID}/payment/${paymentMethodData.paymentMethodId}`,
              paymentMethodData
            )
            .pipe(
              catchError((err) => {
                return throwError(err.error);
              })
            );
        } else {
          return throwError("Customer not found");
        }
      })
    );
  }

  deletePaymentMethod(paymentMethodId: string) {
    if (
      this.selectedPaymentMethod.getValue().paymentMethodId === paymentMethodId
    ) {
      this.getDefaultPaymentMethod().subscribe((data) => {
        this.selectedPaymentMethod.next(data);
      });
    }
    // return of(null);
    return this.customerService.customer.pipe(
      mergeMap((data) => {
        if (data.customerID) {
          return this.http
            .delete(
              `/api/customer/${data.customerID}/payment/${paymentMethodId}`
            )
            .pipe(
              map((response) => {
                if (
                  this.selectedPaymentMethod.getValue().paymentMethodId ===
                  paymentMethodId
                ) {
                  this.getDefaultPaymentMethod().subscribe(
                    (data) => {
                      this.selectedPaymentMethod.next(data);
                    },
                    (err) =>
                      this.selectedPaymentMethod.next(new PaymentMethod())
                  );
                }
              }),
              catchError(() => throwError("Payment Method not deleted"))
            );
        } else {
          return of(null);
        }
      })
    );
  }

  setDefaultPaymentMethod(paymentMethodId: string): Observable<boolean> {
    // console.log(typeof addressId);
    let body = JSON.stringify(paymentMethodId);
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return this.http.patch(`/api/payment/default`, body, options).pipe(
      map((response) => true),
      catchError(() => throwError("Unable to get default payment method"))
    );
  }
}
