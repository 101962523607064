import { Deserializable } from "./deserializable.model";
import { Customer } from "./customer.model";
import { PaymentMethod } from "./payment-method.model";
import { PaymentTypes } from "./transaction.model";

export class OtpCustomer implements Deserializable {
  public customer: Customer;
  public paymentMethod: PaymentMethod;
  public remittanceAccount: string;
  public paymentTotal: number;
  public paymentType: PaymentTypes;
  public googleRecaptchaResponse: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
