import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, shareReplay, tap, catchError } from "rxjs/operators";
import { Observable, BehaviorSubject, of } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private _isLoggedIn: boolean = false;
  private _checkedLoggedIn: boolean = false;
  private _isLoggedInObservable: Observable<boolean>;
  public loggedIn: boolean = false;
  constructor(private http: HttpClient) {
    // this.checkWindowsAuth().subscribe((data) => {
    //   console.log(data);
    //   console.log("login status ", this.loggedIn);
    //   this.loggedIn.next(true);
    // });
  }

  checkWindowsAuth(): Observable<boolean> {

    if (this._checkedLoggedIn) {
      return of(this._isLoggedIn);
    } else if (this._isLoggedInObservable != null) {
      return this._isLoggedInObservable;
    } else {
      this._isLoggedInObservable = this.http
        .get("/api/customer/authenticate")
        .pipe(
          map((response: Response) => {
            this._checkedLoggedIn = true;
            this._isLoggedIn = true;
            return this._isLoggedIn;
          })
          // catchError((err) => of(false))
        );

      return this._isLoggedInObservable;
    }
    // return this.http.get("/api/customer/authenticate").pipe(
    //   map((data) => {
    //     console.log(data);

    //     return true;
    //   }),
    //   catchError((err) => of(false))
    // );
  }


  public get isLoggedIn() : boolean {
    return this._isLoggedIn;
  }

}
