import { Component, OnInit } from "@angular/core";
import { Auth0Service } from "src/app/services/Auth/auth0.service";

@Component({
  selector: "app-login-handle",
  templateUrl: "./login-handle.component.html",
  styleUrls: ["./login-handle.component.css"]
})
export class LoginHandleComponent implements OnInit {
  constructor(private auth0Service: Auth0Service) {}

  ngOnInit() {}
}
