import { Component, OnInit, Input } from "@angular/core";
import {
  VANTAGE_THEME,
  BCBSLA_THEME,
  VANTAGE,
  BCBSLA,
} from "src/app/CommonModule/constants";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  spinnerImgUrl: string;

  @Input()
  for: string;

  loaderColor: string = "vantage-loader-colors";
  altText: string = "Logo";
  constructor() {}

  ngOnInit() {
    if (environment.organization === VANTAGE) {
      this.loaderColor = "vantage-loader-colors";
      this.spinnerImgUrl = "../../../assets/logos/VantageLogo_Blue.svg";
      this.altText = "Vantage Logo";
    } else if (environment.organization === BCBSLA) {
      this.loaderColor = "bcbsla-loader-colors";
      this.spinnerImgUrl = "../../../assets/logos/BCBSLALogo_Blue.svg";
      this.altText = "BCBSLA Logo";
    }
  }
}
