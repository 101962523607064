import { Component, OnInit } from "@angular/core";
import { BCBSLA, VANTAGE } from 'src/app/CommonModule/constants';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.css"],
})
export class ErrorComponent implements OnInit {
  imgUrl: string;
  altText: string;
  constructor() {
    if (environment.organization === VANTAGE) {
      this.imgUrl = "../../../assets/logos/VantageLogo_Blue.svg";
      this.altText = "Vantage Logo";
    } else if(environment.organization === BCBSLA) {
      this.imgUrl = "../../../assets/logos/BCBSLALogo_Blue.svg";
      this.altText = "BCBSLA Logo";
    }
  }

  ngOnInit() {}
}
