import { Deserializable } from "./deserializable.model";
import { Customer } from "./customer.model";
import { PaymentMethod } from "./payment-method.model";
import { PaymentTypes } from "./transaction.model";

export class HixCustomer implements Deserializable {
  public customer: Customer;
  public paymentMethod: PaymentMethod;
  public remittanceAccount: string;
  public paymentTotal: number;
  public paymentType: PaymentTypes;
  public googleRecaptchaResponse: string;
  public SSN: string;
  public DOB: Date;
  public phone: string;
  public exchangeId: string;
  public confirmationNumber: string;
  public remittanceGuid: string;
  public fname: string;
  public lname: string;
  public subscriberId: string;
  
  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
