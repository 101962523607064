import { Deserializable } from "./deserializable.model";
import { Address } from "./address.model";

export class PaymentMethod implements Deserializable {
  public id: number;
  public customerId: string;
  public paymentMethodId: string;
  public type: PaymentMethodType;
  public nameOnAccount: string;
  public cardNumber: string;
  public cardExp: string;
  public lastFour: string;
  public routingNum: string;
  public accountNum: string;
  public accountType: AccountType;
  public address: Address;
  public bankName: String;

  deserialize(input: any): this {
    // console.log(input);

    Object.assign(this, input);
    this.address = new Address().deserialize(input.address);
    return this;
  }
}

export enum PaymentMethodType {
  Credit_Card = 1,
  Check = 2,
  ACH = 3,
  Cash = 5,
}

export enum AccountType {
  Checking = 1,
  Savings = 2,
}
