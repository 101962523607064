import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecordingService {

  constructor(
    private http: HttpClient    
  ) 
  {

  }

  pause(): Observable<boolean> {
    try{
      return this.http.get<boolean>(
      "/api/RecordingControls/Pause",
      {  }
    );
    } catch(e){
      console.log(e);
    }    
  }

  resume(): Observable<boolean> {
    return this.http.get<boolean>(
      "/api/RecordingControls/Resume",
      {  }
    );
  }
}

