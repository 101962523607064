import { Address } from "../models/address.model";

export abstract class AddressUtils {
  static WebUser = "WebUser";
  static AdminUser = "AdminUser";

  static isAddressEqual(address1: Address, address2: Address): boolean {
    return (
      address1.city === address2.city &&
      address1.line1 === address2.line1 &&
      address1.line2 === address2.line2 &&
      address1.phone === address2.phone &&
      address1.state === address2.state &&
      address1.zip === address2.zip
    );
  }

  public static getUniqueAddresses(addresses: Address[]) {
    return addresses.reduce((acc, current) => {
      const x = acc.find((item) => this.isAddressEqual(item, current));
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  public static getIdFromAddress(addresses: Address[], address: Address): string | null{
    let finalAddress = addresses.filter(a => {
      return this.isAddressEqual(a, address);
    });
    if(finalAddress.length > 0)
      return finalAddress[0].addressId;
    return null;
  }
}
