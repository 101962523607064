import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Auth0Service } from "src/app/services/Auth/auth0.service";
// import { AuthenticationService } from "src/app/services/Auth/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  title: string;
  backButtonVisible: boolean = false;
  constructor(
    public auth0: Auth0Service,
    // public auth: AuthenticationService,
    public router: Router,
    public location: Location
  ) {
    this.location.onUrlChange((url) => {
      switch (url) {
        case "/portal-payment/paymentmethods":
        case "/admin-payment/paymentmethods":
        case "/one-time-payment/paymentmethods":
          this.title = "Select a payment method";
          this.backButtonVisible = true;
          break;
        case "/portal-payment/paymentmethods/newcc":
        case "/portal-payment/paymentmethods/newach":
        case "/admin-payment/paymentmethods/newcc":
        case "/admin-payment/paymentmethods/newach":
        case "/one-time-payment/paymentmethods/newcc":
        case "/one-time-payment/paymentmethods/newach":
          this.title = "Add a payment method";
          this.backButtonVisible = true;
          break;

        case "/portal-payment/paymentmethods/edit":
        case "/admin-payment/paymentmethods/edit":
          this.title = "Edit a payment method";
          this.backButtonVisible = true;
          break;
        // case "/portal-payment/addresses":
        //   this.title = "Select a billing address";
        //   this.backButtonVisible = true;
        //   break;
        // case "/portal-payment/addresses/new":
        //   this.title = "Add a new billing address";
        //   this.backButtonVisible = true;
        //   break;
        case "/portal-payment/review":
        case "/admin-payment/review":
        case "/one-time-payment/review":
          this.title = "Review";
          this.backButtonVisible = true;
          break;
        case "/admin-payment/stop-recurring":
          this.title = "Stop Payments";
          this.backButtonVisible = true;
          break;
        default:
          this.title = "Make a payment";
          this.backButtonVisible = false;
          break;
      }
    });
  }

  ngOnInit() {}

  popScreen() {
    let url = this.router.url.split("/");
    url.pop();
    this.router.navigate(url);
  }
}
