import { Component, OnInit } from "@angular/core";
import { PaymentService } from "src/app/services/Payment/payment.service";
import { PaymentMethod } from "src/app/models/payment-method.model";
import { AddressService } from "src/app/services/Address/address.service";
import { BehaviorSubject } from "rxjs";
import { Address } from "src/app/models/address.model";
import { TransactionService } from "src/app/services/Transaction/transaction.service";
import {
  TransactionSelectionBox,
  Transaction,
  PaymentTypes,
} from "src/app/models/transaction.model";
import { ZoidHelperService } from "src/app/services/ZoidHelper/zoid-helper.service";
import { LoaderService } from "src/app/services/Loader/loader.service";
import { finalize } from "rxjs/operators";
import { CustomerService } from "src/app/services/Customer/customer.service";
import { Router } from "@angular/router";
import { BCBSLA, VANTAGE } from 'src/app/CommonModule/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-payment-review",
  templateUrl: "./payment-review.component.html",
  styleUrls: ["./payment-review.component.css"],
})
export class PaymentReviewComponent implements OnInit {
  public selectedPaymentMethod$: BehaviorSubject<PaymentMethod>;
  public selectedAddress$: BehaviorSubject<Address>;
  public paymentType$: BehaviorSubject<PaymentTypes>;
  public paymentTotal: number;
  public selectedTotal: number;
  public selectedBox: number;
  public paymentAmount;
  public isDone = false;
  public error = false;
  companyName: string;
  PaymentTypes = PaymentTypes;
  public submitButtonName = "Confirm Payment";
  constructor(
    private customerService: CustomerService,
    private paymentService: PaymentService,
    private addressService: AddressService,
    private transactionService: TransactionService,
    private zoidHelperService: ZoidHelperService,
    private loaderService: LoaderService,
    private router: Router
  ) {
    this.customerService.customer.subscribe((customer) => {
      if (customer.customerID === undefined)
        this.router.navigateByUrl("/error");
    });
    this.selectedPaymentMethod$ = this.paymentService.selectedPaymentMethod;
    this.selectedAddress$ = this.addressService.selectedAddress;
    this.zoidHelperService.paymentTotal$.subscribe(
      (data) => (this.paymentTotal = data)
    );
    this.zoidHelperService.selectedTotal$.subscribe(
      (data) => (this.selectedTotal = data)
    );
    this.zoidHelperService.selectedBox$.subscribe(
      (data) => (this.selectedBox = data)
    );
    this.paymentType$ = this.zoidHelperService.paymentType$;
    if (environment.organization === VANTAGE)
      this.companyName = "Vantage Health Plan, Inc";
    else if (environment.organization === BCBSLA)
      this.companyName = "Blue Cross and Blue Shield of Louisiana";
  }

  ngOnInit() {
    if (this.selectedBox === TransactionSelectionBox.Input) {
      this.paymentAmount = this.selectedTotal;
    } else {
      this.paymentAmount = this.paymentTotal;
    }

    if(this.transactionService.isEditingTransaction)
      this.submitButtonName = "Update Recurring Payment";
  }

  onConfirm(pm: PaymentMethod, sa: Address, price: number, type: PaymentTypes) {
    let transaction = new Transaction();
    transaction.customerId = pm.customerId;
    transaction.paymentMethodId = pm.paymentMethodId;
    transaction.paymentTotal = price;
    transaction.paymentType = type;
    // transaction.remittanceAccountValue = this.customerService.remittanceAccountValue$;
    console.log(transaction.remittanceAccountValue);
    // transaction.remittanceAccountId = this.customerService.rem;
    if(this.customerService.selectedDraftDay.getValue() > 0 ){
      transaction.draftDay = this.customerService.selectedDraftDay.getValue();
    }
    if(this.transactionService.isEditingTransaction){
      this.transactionService.editingTransaction.subscribe(
        t => {
          console.log("t",t);
          transaction.remittanceAccountId = t.remittanceAccountId;
          transaction.recurringPaymentId =t.recurringPaymentId;
          transaction.draftDay = this.customerService.selectedDraftDay.getValue();
          this.editRecurringPayment(transaction);
        },
        err=> console.log(err)
      )

    }
    else {
      console.log("no editing");
      transaction.remittanceAccountId = this.zoidHelperService.remmitanceAccountId$.value;
      transaction.remittanceAccountValue = this.zoidHelperService.remmitanceAccountValue$.value;
      this.createTransaction(transaction);
    }

  }

  cancelPayment(){
    this.zoidHelperService.closeFunc();
  }

  createTransaction(transaction: Transaction) {
    this.loaderService.showLoadingSpinner();
    this.transactionService.submitTransaction(transaction).subscribe(
      (data) => {
        this.zoidHelperService.onPaymentReceivedFunc(data);
        this.loaderService.hideLoadingSpinner();
        this.isDone = true;
        setTimeout(() => {
          this.zoidHelperService.closeFunc();
        }, 2000);
      },
      (err) => {
        this.loaderService.hideLoadingSpinner();
        this.error = true;
      }
    );
  }

  editRecurringPayment(transaction: Transaction) {
    this.transactionService.editRecurringPayment(transaction)
    .subscribe(
      data => {
        this.loaderService.hideLoadingSpinner();
        this.isDone = true;
        setTimeout(() => {
          this.zoidHelperService.closeFunc();
        }, 2000);
      },
      (err) => {
        this.loaderService.hideLoadingSpinner();
        this.error = true;
      }
    )
  }
}
