import { Component, OnInit, ViewChild } from "@angular/core";
import { EmbeddableWidget } from "@acpaas-ui/ngx-embeddable-widgets";
import { ZoidHelperService } from "src/app/services/ZoidHelper/zoid-helper.service";
import { Address } from "src/app/models/address.model";
import { AddressService } from "src/app/services/Address/address.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CreditCardComponent } from "../credit-card/credit-card.component";
import { AddAddressComponent } from "src/app/components/add-address/add-address.component";
import { PaymentMethod } from "src/app/models/payment-method.model";

import { Customer, CustomerType } from "src/app/models/customer.model";
import { CustomerService } from "src/app/services/Customer/customer.service";
import { TransactionService } from "src/app/services/Transaction/transaction.service";
import { PaymentTypes } from "src/app/models/transaction.model";
import { HixTransaction } from "src/app/models/hixTransaction.model";
import {
  DialogData,
  DialogType,
  DialogComponent,
} from "src/app/components/dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { LoaderService } from "src/app/services/Loader/loader.service";
import { Observable, BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";
import { PaymentService } from "src/app/services/Payment/payment.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { OpenPaymentCustomer } from "src/app/models/open-payment-customer.model";

@Component({
  selector: 'app-open-payment',
  templateUrl: './open-payment.component.html',
  styleUrls: ['./open-payment.component.scss']
})
@EmbeddableWidget("../../assets/widget.json")
export class OpenPaymentComponent implements OnInit {
  public remittanceAccountId$;
  public remittanceAccountValue$;
  public remittanceAccountType$;
  demoForm: FormGroup;
  isLoading: boolean = false;
  isDone: boolean = false;
  error: boolean = false;
  openPaymentCustomer: OpenPaymentCustomer;
  paymentTotal$;
  paymentType$;
  amount: FormControl = new FormControl("", [Validators.nullValidator]);
  public selectedTotal$: BehaviorSubject<number>;
  props;

  @ViewChild(AddAddressComponent, { static: false }) addressChild;
  @ViewChild(CreditCardComponent, { static: false }) creditCardChild;
  constructor(
    public dialog: MatDialog,
    private zoidHelperService: ZoidHelperService,
    private addressService: AddressService,
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private transactionService: TransactionService,
    private paymentService: PaymentService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
  ) {
    this.openPaymentCustomer = new OpenPaymentCustomer();
    this.paymentTotal$ = this.zoidHelperService.paymentTotal$;
    this.openPaymentCustomer.customer = new Customer();
    this.openPaymentCustomer.paymentMethod = new PaymentMethod();
    this.paymentType$ = this.zoidHelperService.paymentType$;
    this.openPaymentCustomer.googleRecaptchaResponse = null;
    this.remittanceAccountId$ = this.zoidHelperService.remmitanceAccountId$;
    this.remittanceAccountValue$ = this.zoidHelperService.remmitanceAccountValue$;
    this.selectedTotal$ = this.zoidHelperService.selectedTotal$;
  }

  ngOnInit() {    
    this.demoForm = this.formBuilder.group({
      firstname: new FormControl("", [Validators.nullValidator]),
      lastname: new FormControl("", [Validators.nullValidator]),
      dob: new FormControl("", [Validators.nullValidator]),
      ssn: new FormControl("", [Validators.nullValidator]),
      phone: new FormControl("", [Validators.pattern('/[0-9]+-[0-9]+-[0-9]+/i')]),
      email: new FormControl("", [Validators.nullValidator]),
      amount: new FormControl("", [Validators.required]),
      mbi: new FormControl("",[Validators.nullValidator])
    });
    console.log("init")
    this.amount.valueChanges.subscribe((data) => {
      this.selectedTotal$.next(data);
    });
  }

  public auiOnWidgetInit(props) {
    console.log("props in: ",props)
    if (props) {
      console.log("props: ",props)
      this.props = props;
      this.paymentTotal$.next(props.price);
      this.zoidHelperService.onPaymentReceivedFunc = props.onPaymentReceived;
      this.zoidHelperService.closeFunc = props.close;
      this.remittanceAccountId$.next(props.remittanceAccountId);
      let customer: Customer = new Customer();
      customer.type = CustomerType.Member;
      // customer.customerID = props.CustomerId;
      if (props.customerId) {
        customer.customerID = props.customerId;
        console.log("customerid: ",props.customerId);
      }
      this.customerService.customer.next(customer);      
      this.openPaymentCustomer.customer = customer;
      if (!isNaN(props.paymentType)) {
        this.zoidHelperService.paymentType$.next(props.paymentType);
      }
      if (props.confirmationNumber) {
        this.openPaymentCustomer.confirmationNumber = props.confirmationNumber;
      }
      console.log("cn: ",props.confirmationNumber)
      if (props.remittanceGuid) {
        this.openPaymentCustomer.remittanceGuid = props.remittanceAccountGuid;
      }
      if (props.remittanceAccountValue) {
        this.openPaymentCustomer.remittanceAccount = props.remittanceAccountValue;
      }
      if (props.remittanceAccountGuid) {
        this.openPaymentCustomer.remittanceGuid = props.remittanceAccountGuid;
      }
      
      if(props.dob){
        this.demoForm.patchValue({dob: props.dob})
      }
      if(props.ssn){
        this.demoForm.patchValue({ssn: props.ssn})
      }
      if(props.mbi){
        this.openPaymentCustomer.mbi = props.mbi;
      }
      if(props.email){
        this.demoForm.patchValue({email: props.email})
      }
      if(props.firstName){
        this.demoForm.patchValue({firstname: props.firstName})
      }
      if(props.lastName){
        this.demoForm.patchValue({lastname: props.lastName})
      }

      console.log("opcu: ",this.openPaymentCustomer);
    } else {
      console.log("no props")
    }
  }
  openRecaptchaAlert() {
    let dialogData = new DialogData();
    dialogData.message =
      "Please complete reCaptcha challange before submitting!";
    dialogData.type = DialogType.Alert;
    let dialogRef = this.dialog.open(DialogComponent, {
      data: dialogData,
    });
  }
  resolved(captchaResponse: string, res) {
    this.openPaymentCustomer.googleRecaptchaResponse = captchaResponse;
  }

  useRegex(input) {
    let regex = /[0-9]+-[0-9]+-[0-9]+/i;
    return regex.test(input);
  }

  onSubmit() {
    let address = this.addressChild.getAddress();
    let paymentMethod = this.creditCardChild.getCreditCard();
    this.openPaymentCustomer.SSN = this.demoForm.get('ssn').value
    this.openPaymentCustomer.DOB = this.demoForm.get('dob').value
    this.openPaymentCustomer.phone = this.demoForm.get('phone').value
    this.openPaymentCustomer.lastname = this.demoForm.get('lastname').value;
    this.openPaymentCustomer.firstname = this.demoForm.get('firstname').value;
    this.openPaymentCustomer.phone = this.demoForm.get('phone').value;
    this.openPaymentCustomer.email = this.demoForm.get('email').value;
    if (address instanceof Address && paymentMethod instanceof PaymentMethod) {
      if (this.openPaymentCustomer.googleRecaptchaResponse === null) {
        this.openRecaptchaAlert();
        return;
      }
      this.loaderService.showLoadingSpinner();
      paymentMethod.address = address;
      this.openPaymentCustomer.customer.fullName = paymentMethod.nameOnAccount;
      this.openPaymentCustomer.paymentMethod = paymentMethod;
      this.openPaymentCustomer.paymentType = this.zoidHelperService.paymentType$.value;
      this.openPaymentCustomer.paymentTotal = this.demoForm.get('amount').value;
      this.transactionService.submitOpenPayment(this.openPaymentCustomer).subscribe(
        (data) => {
          if (
            this.zoidHelperService.paymentType$.value ===
            PaymentTypes.RecurringPayment
          ) {
            let openPaymentTransaction = new HixTransaction();
            openPaymentTransaction.fullName = this.openPaymentCustomer.customer.fullName;
            openPaymentTransaction.customerId = data.customerId;
            openPaymentTransaction.recurringPaymentId = data.recurringPaymentId;
            openPaymentTransaction.applicationConfirmationNumber = this.openPaymentCustomer.remittanceAccount;
            this.zoidHelperService.onPaymentReceivedFunc(openPaymentTransaction);
          } else {
            this.zoidHelperService.onPaymentReceivedFunc(data.transactionId);
          }
          this.loaderService.hideLoadingSpinner();
          this.isDone = true;
          setTimeout(() => {
            this.zoidHelperService.closeFunc();
          }, 3000);
        },
        (err) => {
          this.isLoading = false;
          this.error = true;
        }
      );
    }
  }

  closeWindow() {
    this.zoidHelperService.closeFunc();
  }
}
