import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AddAddressComponent } from 'src/app/components/add-address/add-address.component';
import { DialogComponent, DialogData, DialogType } from 'src/app/components/dialog/dialog.component';
import { Address } from 'src/app/models/address.model';
import { PaymentMethod, PaymentMethodType } from 'src/app/models/payment-method.model';
import { AddressService } from 'src/app/services/Address/address.service';
import { PaymentService } from 'src/app/services/Payment/payment.service';

@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.scss']
})
export class EditPaymentComponent implements OnInit {

  public addAddress: boolean = false;
  public editingPaymentMethod: PaymentMethod;
  @ViewChild(AddAddressComponent, { static: false }) addressChild;
  PaymentMethodType = PaymentMethodType;
  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private addressService: AddressService,
    private dialog: MatDialog
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.editingPaymentMethod = this.router.getCurrentNavigation().extras.state.data.paymentMethod;
    }
   }

  ngOnInit() {
  }

  onSubmit() {
    let paymentMethod : PaymentMethod = new PaymentMethod();
    let address = this.addressChild.getAddress();
    if (address instanceof Address) {
      paymentMethod.paymentMethodId = this.editingPaymentMethod.paymentMethodId;
      paymentMethod.address = address;

      if (address !== null) {
        this.paymentService.updatePaymentMethod(paymentMethod).subscribe(
          (data) => this.popScreen(),
          (err) => {
            let dialogData = new DialogData();
            dialogData.message = err;
            dialogData.type = DialogType.Alert;
            let dialogRef = this.dialog.open(DialogComponent, {
              data: dialogData,
            });
          }
        );
      }
    }
  }

  toggleAddAddress() {
    this.addAddress = !this.addAddress;
  }

  removeAllSpaces(str: string): string {
    return str.replace(/\s/g, "");
  }

  popScreen(event?) {
    if(event) {
      event.preventDefault();
    }
    let url = this.router.url.split("/");
    url.pop();
    this.router.navigate(url);
  }

}
