import { Component, OnInit } from "@angular/core";
import { PaymentService } from "src/app/services/Payment/payment.service";
import { Observable } from "rxjs";
import { Address } from "src/app/models/address.model";
import { AddressService } from "src/app/services/Address/address.service";
import { Router } from "@angular/router";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-select-address",
  templateUrl: "./select-address.component.html",
  styleUrls: ["./select-address.component.css"],
})
export class SelectAddressComponent implements OnInit {
  public addresses$: Observable<Address[]>;
  public selectedAddress: Address;

  constructor(
    private addressService: AddressService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.addresses$ = this.addressService.getAddresses();
    this.addressService.selectedAddress.subscribe(
      (data) => (this.selectedAddress = data)
    );
  }

  ngOnInit() {}

  selectAddress(address: Address) {
    this.addressService.selectedAddress.next(address);
    this.router.navigate(["/", "portal-payment"]);
    // this.addressService
    //   .setDefaultAddress(address.addressId)
    //   .subscribe(res => );
  }

  openRemoveDialog(addressId: string) {
    let dialogRef = this.dialog.open(DialogComponent, {
      data: {
        message: "This address will be removed.",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "true") {
        this.addressService.deleteAddress(addressId).subscribe((res) => {
          this.addresses$ = this.addressService.getAddresses();
        });
      }
    });
  }
}
