import * as ngCore from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EmbeddableWidgetsModule } from "@acpaas-ui/ngx-embeddable-widgets";
import { HttpClientModule } from "@angular/common/http";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { RecaptchaModule } from "ng-recaptcha";

import { AppRoutingModule } from "./CommonModule/app-routing.module";
import { AppComponent } from "./app.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { MaterialModule } from "./CommonModule/app-material.module";
import { HomeComponent } from "./pages/home/home.component";
import { SelectPaymentComponent } from "./pages/select-payment/select-payment.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { AddPaymentComponent } from "./pages/add-payment/add-payment.component";
import { CreditCardComponent } from "./pages/credit-card/credit-card.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { SelectAddressComponent } from "./pages/select-address/select-address.component";
import { AddAddressComponent } from "./components/add-address/add-address.component";
import { LogoutComponent } from "./pages/logout/logout.component";
import { LoginHandleComponent } from "./components/login-handle/login-handle.component";
import { PaymentReviewComponent } from "./pages/payment-review/payment-review.component";
import { ErrorComponent } from "./pages/error/error.component";
import { AddAchComponent } from "./pages/add-ach/add-ach.component";
import { OneTimePaymentComponent } from "./pages/one-time-payment/one-time-payment.component";
import { NumberOnlyDirective } from "./directives/number.directive";
import { MatFormFieldModule, MatInputModule, MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material';
import { EditPaymentComponent } from './pages/edit-payment/edit-payment.component';
import { StopRecurringPaymentComponent } from './pages/stop-recurring-payment/stop-recurring-payment.component';
import { MemberStopRecurringPaymentComponent } from './pages/member-stop-recurring-payment/member-stop-recurring-payment.component';
import { HixRedirectComponent } from './pages/hix-redirect/hix-redirect.component';
import { OpenPaymentComponent } from './pages/open-payment/open-payment.component';
import { PhoneMasksDirectiveDirective } from './directives/phone-masks-directive.directive';
// declare var Iconfig: any;
// export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    AppComponent,
    CheckoutComponent,
    NavbarComponent,
    ProfileComponent,
    HomeComponent,
    SelectPaymentComponent,
    SpinnerComponent,
    AddPaymentComponent,
    CreditCardComponent,
    DialogComponent,
    SelectAddressComponent,
    AddAddressComponent,
    LogoutComponent,
    LoginHandleComponent,
    PaymentReviewComponent,
    ErrorComponent,
    AddAchComponent,
    OneTimePaymentComponent,
    NumberOnlyDirective,
    EditPaymentComponent,
    StopRecurringPaymentComponent,
    MemberStopRecurringPaymentComponent,
    HixRedirectComponent,
    OpenPaymentComponent,
    PhoneMasksDirectiveDirective
  ],
  entryComponents: [DialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    EmbeddableWidgetsModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    RecaptchaModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule
  ],
  exports: [PhoneMasksDirectiveDirective],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
