import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from "@angular/core";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { AddressService } from "src/app/services/Address/address.service";
import { Address } from "src/app/models/address.model";
import { CustomerService } from "src/app/services/Customer/customer.service";
import { Customer } from "src/app/models/customer.model";
import { states } from "./allStates";
import { AddressUtils } from "src/app/CommonModule/addressUtils";
import { BehaviorSubject, Subscription } from "rxjs";
import { finalize } from 'rxjs/operators';
import { PaymentService } from 'src/app/services/Payment/payment.service';
import { isEmpty } from "src/app/CommonModule/isEmpty";

@Component({
  selector: "app-add-address",
  templateUrl: "./add-address.component.html",
  styleUrls: ["./add-address.component.css"],
})
export class AddAddressComponent implements OnInit, OnDestroy {
  objectKeys = Object.keys;
  addAddressForm;
  public customer: Customer;
  public allStates = states;
  public isLoading: boolean;

  public addresses: BehaviorSubject<Address[]>;
  public addingAddress: boolean = false;
  @Input()
  selectedAddress: Address;
  @Output()
  addressEvent = new EventEmitter<Address>();
  subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private addressService: AddressService,
    private customerService: CustomerService,
    private paymentService: PaymentService
  ) {
    // this.customerService.getCustomer().subscribe((data) => {
    //   this.customer = data;
    //   console.log(typeof data);
    // });

    this.customerService.customer.subscribe(
      (customer) => {
        if(customer.customerID) {
          this.subscription =
          this.paymentService.paymentMethodsSubject.subscribe(
          pms => {
            if(pms !=null && pms.length  >0) this.getAddressesAttachedToPaymentMethods();
            else this.getAllAddresses();
          });
          (this.customer = customer)
        } else {
          this.addingAddress = true;
        }
      },
      (err) => console.log(err)
    );

    this.addAddressForm = this.formBuilder.group({
      line1: new FormControl("", [Validators.required]),
      line2: new FormControl("", []),
      city: new FormControl("", [Validators.required]),
      state: new FormControl("", [Validators.required]),
      zip: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
      phone: new FormControl("", [
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      addressRadio: new FormControl("", []),
    });

    this.addresses = this.addressService.addresses;



  }
  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  ngOnInit() {}

  getAddress(): Address {
    let address : Address;
    let addressSource: string = AddressUtils.WebUser;
    this.addAddressForm.markAllAsTouched();
    if (this.addingAddress && this.addAddressForm.valid) {
      address = new Address().deserialize(this.addAddressForm.value);
      address.line2 = address.line2 === null? "": address.line2;
      address.phone = address.phone === null? "": address.phone;

    } else if (!this.addingAddress) {
      if (this.addAddressForm.controls.addressRadio.value) {
        address = this.addresses.value.filter(
          (address) =>
            address.addressId ===
            this.addAddressForm.controls.addressRadio.value
        )[0];
      } else {
        address = this.addresses.value[0];
      }
    } else {
      return null;
    }

    if (this.customer && this.customer.fullName) {
      address.name = this.customer.fullName + "'s Address";
    }
    if(this.router.url.split("/")[1] === "admin-payment"){
      address.addressSource = AddressUtils.AdminUser;
    } else {
      address.addressSource = AddressUtils.WebUser;
      address.name = isEmpty(address.name) ? "OTP User Address": address.name;
    }
    return address;
  }

  toggleAddAddress() {
    this.addingAddress = !this.addingAddress;
    this.resetAddressForm();
  }

  resetAddressForm() {
    for (
      let index = 0;
      index < Object.keys(this.addAddressForm.controls).length - 1;
      index++
    ) {
      const element = Object.keys(this.addAddressForm.controls)[index];
      this.addAddressForm.controls[element].reset();
    }
    //  Object.keys(this.addAddressForm.controls).forEach((key) => {
    //    this.addAddressForm.controls[key].markAsDirty();
    //  });
  }

  getAddressesAttachedToPaymentMethods(){
    this.isLoading = true;
    this.addressService
    .getAddresses()
    .subscribe(
      (data) => {
        if (data && data.length > 0) {
          this.addingAddress = false;
          const uniqueAddresses = AddressUtils.getUniqueAddresses(data);
          if(this.selectedAddress) {
            let addressId = AddressUtils.getIdFromAddress(uniqueAddresses, this.selectedAddress);
            this.addAddressForm.patchValue({
              addressRadio: addressId,
            });
          } else {
            this.addAddressForm.patchValue({
              addressRadio: uniqueAddresses[0].addressId,
            });
          }
          this.addressService.addresses.next(uniqueAddresses);
          this.isLoading = false;
          // this.addresses = uniqueAddresses;
          // this.addPaymentMethodForm.
        }
        this.addressService.addresses.subscribe((address) => {
          if (address.length === 0) this.addingAddress = true;
          else {
            this.addingAddress = false;
          }
        });
        // else if (this.addressService.addresses.value.length === 0) {
        // this.addingAddress = true;
        // this.addressService.addresses.next(data);
        // this.addresses = data;
        // }
      },
      (err) => {
        this.addingAddress = true;
        console.log(err);
      }
    );
  }

  getAllAddresses(){
    this.isLoading = true;
    this.addressService
    .getAllAddresses()
    .subscribe(
      (data) => {
        if (data && data.length > 0) {
          this.addingAddress = false;
          const uniqueAddresses = AddressUtils.getUniqueAddresses(data);
          if(this.selectedAddress) {
            let addressId = AddressUtils.getIdFromAddress(uniqueAddresses, this.selectedAddress);
            this.addAddressForm.patchValue({
              addressRadio: addressId,
            });
          } else {
            this.addAddressForm.patchValue({
              addressRadio: uniqueAddresses[0].addressId,
            });
          }

          this.addressService.addresses.next(uniqueAddresses);
          this.isLoading = false;
          // this.addresses = uniqueAddresses;
          // this.addPaymentMethodForm.
        }
        this.addressService.addresses.subscribe((address) => {
          if (address.length === 0) this.addingAddress = true;
          else {
            this.addingAddress = false;
          }
        });
        // else if (this.addressService.addresses.value.length === 0) {
        // this.addingAddress = true;
        // this.addressService.addresses.next(data);
        // this.addresses = data;
        // }
      },
      (err) => {
        this.isLoading = false
        this.addingAddress = true;
        console.log(err);
      }
    );
  }


}
