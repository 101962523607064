import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatButtonModule,
  MatListModule,
  MatDividerModule,
  MatCardModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatRippleModule,
  MatDialogModule,
  MatSelectModule,
  MatRadioModule
} from "@angular/material";
import { MatInputModule } from "@angular/material/input";
import {
  MatDatepickerModule,
  MatDatepickerInputEvent
} from "@angular/material/datepicker";

const MaterialComponents = [
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatButtonModule,
  MatListModule,
  MatDividerModule,
  MatCardModule,
  MatDatepickerModule,
  MatInputModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatRippleModule,
  MatDialogModule,
  MatSelectModule,
  MatRadioModule
];

@NgModule({
  imports: [MaterialComponents],
  exports: [MaterialComponents]
})
export class MaterialModule {}
