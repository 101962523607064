import { Component, OnInit, Input, ɵCompiler_compileModuleSync__POST_R3__ } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { EmbeddableWidget } from "@acpaas-ui/ngx-embeddable-widgets";
import { PaymentService } from "../../services/Payment/payment.service";
import { FormControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CustomerService } from "src/app/services/Customer/customer.service";
import { Customer, NewCustomer } from "src/app/models/customer.model";
import { Observable, BehaviorSubject } from "rxjs";
import { PaymentMethod, PaymentMethodType } from "src/app/models/payment-method.model";
import { Address } from "src/app/models/address.model";
import { AddressService } from "src/app/services/Address/address.service";
import { Auth0Service } from "src/app/services/Auth/auth0.service";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import { TransactionService } from "src/app/services/Transaction/transaction.service";
import { ZoidHelperService } from "src/app/services/ZoidHelper/zoid-helper.service";
import { LoaderService } from "src/app/services/Loader/loader.service";
import { finalize } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { BCBSLA, VANTAGE } from "src/app/CommonModule/constants";
import { PaymentTypes } from "src/app/models/transaction.model";
import { query } from "@angular/animations";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
@EmbeddableWidget("../../assets/widget.json")
export class CheckoutComponent implements OnInit {
  @Input()
  public name: string;
  @Input()
  public price: number;
  @Input()
  public onPaymentReceived;
  companyName: string;

  public customer$: BehaviorSubject<Customer>;
  public selectedPaymentMethod$: BehaviorSubject<
    PaymentMethod
  > = new BehaviorSubject(new PaymentMethod());
  public selectedAddress$: BehaviorSubject<Address>;
  selectedAmount: number;
  public paymentTotal$: BehaviorSubject<number>;
  public selectedTotal$: BehaviorSubject<number>;
  public selectedBox$: BehaviorSubject<number>;
  public remittanceAccountId$;
  public remittanceAccountValue$;
  public remittanceAccountType$;
  public paymentType$: BehaviorSubject<PaymentTypes>;
  public programValue$: BehaviorSubject<string>;
  public policyValue$: BehaviorSubject<string>;
  PaymentTypes = PaymentTypes;
  PaymentMethodType = PaymentMethodType;

  date = new FormControl(new Date());
  amount = new FormControl("", []);
  paymentMethodError;
  newCustomer = false;

  draftDays: any[];
  selectedDay: number;

  transactionServiceRefForTemplate: TransactionService;

  paymentTotalDisplay: number;
  constructor(
    private customerService: CustomerService,
    private paymentService: PaymentService,
    public auth0: Auth0Service,
    private router: Router,
    private transactionService: TransactionService,
    private zoidHelperService: ZoidHelperService,
    private addressService: AddressService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
  ) {
    this.customer$ = this.customerService.customer;
    this.selectedPaymentMethod$ = this.paymentService.selectedPaymentMethod;
    this.paymentTotal$ = this.zoidHelperService.paymentTotal$;
    this.paymentTotalDisplay = 0;
    this.paymentType$ = this.zoidHelperService.paymentType$;
    this.selectedTotal$ = this.zoidHelperService.selectedTotal$;
    this.selectedBox$ = this.zoidHelperService.selectedBox$;
    this.remittanceAccountId$ = this.zoidHelperService.remmitanceAccountId$;
    this.remittanceAccountValue$ = this.zoidHelperService.remmitanceAccountValue$;
    this.programValue$ = this.zoidHelperService.programValue$;
    this.policyValue$ = this.zoidHelperService.policyValue$;
    this.draftDays = this.customerService.draftDays;

    this.amount.valueChanges.subscribe((data) => {
      this.selectedTotal$.next(data);
    });

    this.transactionServiceRefForTemplate = this.transactionService;

    if (environment.organization === VANTAGE)
      this.companyName = "Vantage Health Plan, Inc";
    else if (environment.organization === BCBSLA)
      this.companyName = "Blue Cross and Blue Shield of Louisiana";
  }

  ngOnInit() {
    this.customerService.selectedDraftDay.subscribe(data => {
      this.selectedDay = data;
    })
    this.zoidHelperService.paymentTotal$.subscribe(answer => {
      this.paymentTotalDisplay = answer;
      console.log("answer",answer);
    })
    this.draftDays = this.customerService.programRules.getValue().draftDays;
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date.setValue(event.value);
  }

  public auiOnWidgetInit(props) {
    if (props) {
      this.paymentTotal$.next(props.price);
      this.zoidHelperService.onPaymentReceivedFunc = props.onPaymentReceived;
      this.zoidHelperService.closeFunc = props.close;
      this.remittanceAccountId$.next(props.remittanceAccountId);

      if(props.program){
        this.programValue$.next(props.program);
      }
      if (props.remittanceAccountValue) {
        this.remittanceAccountValue$.next(props.remittanceAccountValue);
      }
      console.log("props.program",props.program);
      if(props.policy){
        this.policyValue$.next(props.policy);
      }
      console.log("props.policy",props.policy);
      if (!isNaN(props.paymentType)) {
        this.zoidHelperService.paymentType$.next(props.paymentType);
      }
      if (props.customerId) {
        this.loaderService.showLoadingSpinner();
        this.customerService
          .getCustomerByIdAndRemittance(props.customerId, this.remittanceAccountValue$.getValue())
          .pipe(
            finalize(() => {
              this.loaderService.hideLoadingSpinner();
            })
          )
          .subscribe(
            (data) => {
              console.log(data)
              this.customerService.customer.next(data)
              console.log("data: ",data);
              console.log(this.customerService.customer.getValue())
              this.customerService.getProgram(this.customerService.customer.getValue(),this.customerService.customer.getValue().remittanceAccounts[0].remittanceAccountId)
              .subscribe( answer => {
                console.log("getprogram answer: ",answer);
                this.customerService.programRules.next(answer);
                this.customerService.programRules.subscribe( programAnswer => {
                  this.draftDays = programAnswer.draftDays;
                  console.log("dd: ",this.draftDays)
                  this.customerService.selectedDraftDay.next(this.draftDays[0].numericDay);
                  this.paymentService.canUseACH.next(programAnswer.canUseACH);
                  this.paymentService.canUseCreditCard.next(programAnswer.canUseCreditCard);
                })
              })
            },
            (err) => {
              this.router.navigateByUrl("/error");
            }
          );

        if (props.remittanceAccountValue) {
          this.remittanceAccountValue$.next(props.remittanceAccountValue);

          if(!this.transactionService.isEditingTransaction && props.paymentType == 2){
            console.log(props.customerId)
            this.transactionService
            .checkRecurringPayments(props.customerId,props.remittanceAccountValue,1)
            .subscribe(
              (data) => {
                this.transactionService.editingTransaction.next(data);
                if(data.recurringPaymentId){
                  this.transactionService.isEditingTransaction = true;
                }
              }
            )
          } else {
            console.log(this.transactionService.editingTransaction.getValue());
          }

        } else {
          this.router.navigateByUrl("/error");
        }
      } else if (props.newCustomerData) {
        let newCustomerData: NewCustomer = new NewCustomer().deserialize(
          props.newCustomerData
        );
        if (newCustomerData.fullName == "" && newCustomerData.email == "" && newCustomerData.remittanceAccountType && newCustomerData.remittanceAccountValue == "") {
          console.log("new customerData if: ",newCustomerData);
          this.router.navigateByUrl("/error");
        } else {
          this.newCustomer = true;
          console.log("new customerData: ",newCustomerData);
          this.remittanceAccountValue$.next(newCustomerData.remittanceAccountValue);
          this.customerService.createCustomer(newCustomerData).subscribe(
            (newCustomerDataReturn) => {
              this.newCustomer = false;
              // this.remittanceAccountId$.next(newCustomerDataReturn.arAccountId);
              this.customerService.getCustomerByIdAndRemittance(newCustomerDataReturn.customerID,this.remittanceAccountValue$.getValue())
              .subscribe(
                (data) => {
                  console.log("getCustomer: ",data);
                  this.customerService.customer.next(data)
                  console.log("data: ",data);
                  console.log(this.customerService.customer.getValue())
                  this.customerService.getProgram(this.customerService.customer.getValue(),this.customerService.customer.getValue().remittanceAccounts[0].remittanceAccountId)
                  .subscribe( answer => {
                    console.log("getprogram answer: ",answer);
                    this.customerService.programRules.next(answer);
                    this.customerService.programRules.subscribe( programAnswer => {
                      this.draftDays = programAnswer.draftDays;
                      console.log("dd: ",this.draftDays)
                      this.customerService.selectedDraftDay.next(this.draftDays[0].numericDay);
                      this.paymentService.canUseACH.next(programAnswer.canUseACH);
                      this.paymentService.canUseCreditCard.next(programAnswer.canUseCreditCard);
                    })
                  })
                },
                (err) => {
                  this.router.navigateByUrl("/error");
                }
              );
            },
            (error) => this.router.navigateByUrl("/error")
          );
        }
      }

      if (props.address) {
        let passedAddress = new Address().deserialize(props.address);
        this.addressService.addresses.next([passedAddress]);
      }

      // this.onPaymentReceived = props.onPaymentReceived;
    } else if (this.router.url.split("?")[0] === "/admin-payment") {
      // this.loaderService.isLoading.next(true);
      this.route.queryParams.subscribe((queryParams) => {
        if(queryParams)
        {
          console.log("query params: ",queryParams)
          if (queryParams.RemittanceAccountId) {
            this.remittanceAccountId$.next(queryParams.RemittanceAccountId);
            console.log("remittanceAcc: ",this.remittanceAccountId$.getValue())
          }
  
          if (!isNaN(queryParams.PaymentType) && queryParams.PaymentType == PaymentTypes.RecurringPayment.toString()) {
            this.loaderService.showLoadingSpinner();
            this.zoidHelperService.paymentType$.next(PaymentTypes.RecurringPayment);
            this.loaderService.hideLoadingSpinner();
          }
  
          if (queryParams.Balance) {
            this.paymentTotal$.next(parseFloat(queryParams.Balance));
          }
          if(queryParams.CustomerId){
            console.log("customer: ",queryParams.CustomerId);
          }
          if (queryParams.remittanceAccountValue) {
            this.remittanceAccountValue$.next(queryParams.remittanceAccountValue);
          }
          this.loaderService.showLoadingSpinner();
          if(queryParams.RecurringPaymentId) {
            console.log("recurring PaymentID: ",queryParams.RecurringPaymentId)
            this.loaderService.showLoadingSpinner();
            this.transactionService.isEditingTransaction = true;
            this.transactionService.getRecurringPayment(queryParams.RecurringPaymentId, queryParams.CustomerId)
            .subscribe(
              rp => {
                console.log("rp: ",rp)
                this.remittanceAccountId$.next(rp.remittanceAccountId)
                this.customerService.getCustomerByIdAndRemittance(rp.customerId, rp.recurringPaymentId)
                .subscribe(answer => {
                  if(answer)
                  {
                    this.customerService.customer.next(answer);
                  }
                })
                this.customerService.getProgramByCustomerId(rp.customerId,rp.remittanceAccountId)
                  .subscribe( answer => {
                    console.log("getprogram answer: ",answer);
                    this.customerService.programRules.next(answer);
                    this.customerService.programRules.subscribe( programAnswer => {
                      this.draftDays = programAnswer.draftDays;
                      this.customerService.selectedDraftDay.next(this.draftDays[0].numericDay);
                      this.paymentService.canUseACH.next(programAnswer.canUseACH);
                      this.paymentService.canUseCreditCard.next(programAnswer.canUseCreditCard);
                    })
                  })
                this.loaderService.showLoadingSpinner();
  
                if(rp != null && rp.recurringPaymentId) {
                  this.transactionService.editingTransaction.next(rp);
                  this.paymentService.getPaymentMethodById(rp.paymentMethodId)
                  .subscribe(
                    pm => {
                      console.log({pm})
                      this.paymentService.selectedPaymentMethod.next(pm);
                      this.loaderService.hideLoadingSpinner();
                    },
                    err => {
                      console.log(err);
                      this.loaderService.hideLoadingSpinner();
                    }
                  );
                  this.zoidHelperService.paymentType$.next(PaymentTypes.RecurringPayment);
                }
              },
              err =>{
                this.router.navigateByUrl("/error");
              }
            );
          } else {
            this.customerService
              .getCustomerByIdAndRemittance(queryParams.CustomerId, queryParams.RemittanceAccountId)
              .pipe(
                finalize(() => {
                  this.loaderService.hideLoadingSpinner();
                })
              )
              .subscribe(
                (data) => {
                  if(data){
                    console.log("getCustomer: ",data);
                    this.customerService.customer.next(data)
                    // console.log("data: ",data);
                    console.log(this.customerService.customer.getValue())
                    this.remittanceAccountId$.next( queryParams.RemittanceAccountId)
                    this.customerService.getProgram(this.customerService.customer.getValue(), queryParams.RemittanceAccountId)
                    .subscribe( answer => {
                      console.log("getprogram answer: ",answer);
                      this.customerService.programRules.next(answer);
                      this.customerService.programRules.subscribe( programAnswer => {
                        this.draftDays = programAnswer.draftDays;
                        this.customerService.selectedDraftDay.next(this.draftDays[0].numericDay);
                        this.paymentService.canUseACH.next(programAnswer.canUseACH);
                        this.paymentService.canUseCreditCard.next(programAnswer.canUseCreditCard);
                      })
                    })
                  } else { console.log("no data") }
                },
                (err) => {
                  console.log("whoops: ",err)
                  // this.router.navigateByUrl("/error");
                }
              );
            }
          this.router.navigateByUrl("/admin-payment");
        }
      });
    } else {
      if(environment.production)
      {
        this.router.navigateByUrl("/error");
      }
      this.loaderService.showLoadingSpinner();
      // TODO: THrow error and route to error page
      this.customerService.getCustomer()
      .pipe(
        finalize(
          ()=>{
            this.loaderService.hideLoadingSpinner();
          }
        )
      )
      .subscribe(
        (data) => this.customerService.customer.next(data),
        (err) => {
          // this.router.navigateByUrl("/error");
          console.error("No customer record found");
        }
      );
    }
  }

  onItemChange(){
    console.log(" Value is : ", this.selectedDay );
    this.customerService.selectedDraftDay.next(this.selectedDay);
  }

  pastDateFilter = (d: Date): boolean => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return d > yesterday;
  };

  payAmountSelection(selection: number) {
    // 1 for default and 2 for input amount
    this.selectedBox$.next(selection);
  }

  stopRecurringPayment(){
    let url = this.router.url
    console.log("url",url);
    if(url.indexOf("admin") > 0) {
      console.log("in admin");
      this.router.navigateByUrl("admin-payment/stop-recurring");
    } else {
      this.router.navigateByUrl("/payment/stop-recurring");
    }
  }

  onSubmit() {
    if (this.selectedPaymentMethod$.getValue().paymentMethodId === undefined) {
      this.paymentMethodError = true;
    } else {
      this.paymentMethodError = false;
      let url = this.router.url.split("/");
      url.push("review");
      this.router.navigate(url);
    }
  }

  closeWindow() {
    this.zoidHelperService.closeFunc();
  }

}
