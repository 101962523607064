import { Deserializable } from "./deserializable.model";

export class Transaction implements Deserializable {
  public customerId: string;
  public transactionId: string;
  public paymentMethodId: string;
  public addressId: string;
  public paymentType: PaymentTypes;
  public paymentTotal: number;
  public status: TransactionStatus;
  public authCode: string;
  public recurringPaymentId: string;
  public remittanceAccountId: string;
  public remittanceAccountValue: string;
  public draftDay: number;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export enum PaymentTypes {
  OneTimePayment = 1,
  RecurringPayment = 2,
  Refund = 3,
}

export enum TransactionStatus {
  New = 11,
  Authorized = 1,
  Captured = 2,
  Settled = 3,
  Voided = 4,
  RefundPending = 5,
  Refunded = 6,
  PartialRefunded = 7,
  Declined = 8,
  Error = 9,
  HeldForReview = 10,
}

export enum TransactionSelectionBox {
  Default = 1,
  Input = 2,
}
