import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Address } from "src/app/models/address.model";
import { Router } from "@angular/router";
import { PaymentService } from "src/app/services/Payment/payment.service";
import { AddressService } from "src/app/services/Address/address.service";
import { AddressUtils } from "src/app/CommonModule/addressUtils";
import {
  PaymentMethod,
  PaymentMethodType,
} from "src/app/models/payment-method.model";
import { PaymentUtils } from "src/app/CommonModule/paymentUtils";
import { AddAddressComponent } from "src/app/components/add-address/add-address.component";
import { ErrorStateMatcher, MatInputModule } from '@angular/material';

@Component({
  selector: "app-add-ach",
  templateUrl: "./add-ach.component.html",
  styleUrls: ["./add-ach.component.scss"],
})
export class AddAchComponent implements OnInit {
  addAchForm: FormGroup;
  public addAddress: boolean = false;
  addresses: Address[];
  errorMatcher = new MyErrorStateMatcher();

  @ViewChild(AddAddressComponent, { static: false }) child;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private paymentService: PaymentService,
    private addressService: AddressService
  ) {
    this.addAchForm = this.formBuilder.group({
      routingNum: new FormControl("", [
        Validators.required,
        Validators.maxLength(9),
        Validators.minLength(9),
      ]),
      accountNum: new FormControl("", [Validators.required]),
      accountNumAgain: new FormControl("", [Validators.required]),
      accountType: new FormControl("", [Validators.required]),
      nameOnAccount: new FormControl("", []),
      addressRadio: new FormControl("", []),
      bankName: new FormControl("", []),
    }, {validator: this.matchValidator});

    this.addressService.getAddresses().subscribe(
      (data) => {
        if (data && data.length > 0) {
          const uniqueAddresses = AddressUtils.getUniqueAddresses(data);
          this.addAchForm.patchValue({
            addressRadio: uniqueAddresses[0].addressId,
          });
          this.addresses = uniqueAddresses;
          // this.addPaymentMethodForm.
        } else {
          this.addAddress = true;
          this.addresses = data;
        }
      },
      (err) => {
        this.addAddress = true;
        console.log(err);
      }
    );
  }

  ngOnInit() {}

  onSubmit() {
    this.addAchForm.controls.accountNumAgain.markAsDirty();
    let paymentMethod = new PaymentMethod().deserialize(this.addAchForm.value);
    paymentMethod.nameOnAccount = paymentMethod.nameOnAccount.length >0 ? paymentMethod.nameOnAccount : "New ACH";
    let address = this.child.getAddress();
    if (address instanceof Address && this.addAchForm.valid) {
      paymentMethod.address = address;
      paymentMethod.routingNum = this.addAchForm.controls.routingNum.value;
      paymentMethod.accountNum = this.addAchForm.controls.accountNum.value;
      paymentMethod.accountType = parseInt(
        this.addAchForm.controls.accountType.value
      );
      paymentMethod.nameOnAccount = this.addAchForm.controls.nameOnAccount.value.length > 0 ?
        this.addAchForm.controls.nameOnAccount.value : "New ACH";
      paymentMethod.type = PaymentMethodType.ACH;
      paymentMethod.bankName = this.addAchForm.controls.bankName.value;
      if (address !== null) {
        this.paymentService.submitPaymentMethod(paymentMethod).subscribe(
          (data) => this.popScreen(),
          (err) => console.log(err)
        );
      }
    }
  }

  popScreen(event?) {
    if(event) {
      event.preventDefault();
    }
    let url = this.router.url.split("/");
    url.pop();
    this.router.navigate(url);
  }

  matchValidator(form: FormGroup) {
    console.log("match validator");
    const condition = form.get('accountNum').value !== form.get('accountNumAgain').value;
    console.log("condition ", condition);
    return condition ? { accountNumDoNotMatch: true} : null;
  }
}

  /** Error when the parent is invalid */
  class MyErrorStateMatcher  implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      // console.log("control.dirty", control.dirty);
      // console.log("formcontrol", form.getError("accountNumDoNotMatch"));

      // console.log("Cross field", control.dirty && form.getError("accountNumDoNotMatch"));
      // const condition = form.form.get('accountNum').value !== form.get('accountNumAgain').value;
      return form.getError("accountNumDoNotMatch");
  }
}
