import { Component, HostListener, Input, OnInit } from "@angular/core";
import { AuthenticationService } from "./services/Auth/authentication.service";
import { slider } from "./CommonModule/route-animations";
import {
  RouterOutlet,
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  NavigationError,
} from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { environment } from "src/environments/environment";
import {
  BCBSLA_THEME,
  VANTAGE_THEME,
  BCBSLA,
  VANTAGE,
} from "./CommonModule/constants";
import { OverlayContainer } from "@angular/cdk/overlay";
import { LoaderService } from "./services/Loader/loader.service";
import { BehaviorSubject } from "rxjs";
import { RecordingService } from "./services/Recording/recording.service";
import { filter } from "rxjs/operators";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  animations: [slider],
})
export class AppComponent implements OnInit {
  isMobile: boolean = true;

  title = "payment-angular";
  appTheme;
  showLoadingIndicator:boolean = true;
  isRouteHome = true;
  isAdmin: boolean
  constructor(
    // public auth: AuthenticationService,
    breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    private overlayContainer: OverlayContainer,
    public loaderService: LoaderService,
    private recordingService: RecordingService
  ) {
    breakpointObserver
      .observe(Breakpoints.HandsetPortrait)
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });

    this.router.events.subscribe((routerEvent) => {
      // if (routerEvent instanceof NavigationStart) {
      //   this.loaderService.isLoading.next(true);
      // }

      // if (routerEvent instanceof NavigationEnd) {
      //   this.loaderService.isLoading.next(false);
      // }

      if (routerEvent instanceof NavigationError) {
        this.loaderService.hideLoadingSpinner();
        this.router.navigateByUrl("/error");
      }
    });

    // this.showLoadingIndicator = this.loaderService.isLoading;
    if (environment.organization === BCBSLA) {
      this.appTheme = BCBSLA_THEME;
    } else if (environment.organization === VANTAGE) {
      this.appTheme = VANTAGE_THEME;
    }
    this.overlayContainer.getContainerElement().classList.add(this.appTheme);
  }
  ngOnInit(): void {
    console.log("about to pause")
    this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {
      if(navEnd.urlAfterRedirects.indexOf('admin-payment') > 0){
        try{
          this.recordingService.pause().subscribe(answer => {
            console.log("in pause sub")
            if(!answer){
              alert("Call not paused");
            } 
          },
          error => {
            console.log("in error")
            console.log(error);
          },
          )
          } catch(e){
            
          }  
      }
    })
    this.route.queryParams.subscribe((data) => {
      if (data.source == "PortalPayment") {
        this.router.navigateByUrl("/portal-payment");
      } else if (data.source == "AdminPayment") {
        this.isAdmin = true;
        console.log("In admin, about to pause")
        try{
          this.recordingService.pause().subscribe(answer => {
            console.log("in pause sub")
            if(!answer){
              alert("Call not paused");
            } 
          },
          error => {
            console.log("in error")
            console.log(error);
          },
          )
        } catch(e){
          
        }        
        
        this.router.navigateByUrl("/admin-payment");
      } else if (data.source == "OneTimePayment") {
        this.router.navigateByUrl("/one-time-payment");
      } else if (data.source == "OpenPayment") {
        this.router.navigateByUrl("/open-payment");
      }
    });
    
  }
 
  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event): void{
    console.log("calling resume")
    this.recordingService.resume().subscribe(answer => {
      console.log("in pause sub");
      if(!answer){
        alert("Call not resumed");
      } 
    })
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    console.log("calling resume")
    this.recordingService.resume().subscribe(answer => {
      console.log("in pause sub");
      if(!answer){
        alert("Call not resumed");
      } 
    })
  }

  prepareRoute(outlet: RouterOutlet) {
    if (!this.isMobile) {
      return outlet;
    } else {
      return (
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData["animation"]
      );
    }
  }
}
