import { Deserializable } from "./deserializable.model";

export class ValidCard implements Deserializable {
  public niceType: string;
  public type: string;
  public patterns: number[];
  public gaps: number[];
  public lengths: number[];
  public code: CvvCode;
  public matchStrength: number;
  deserialize(input: any): this {
    Object.assign(this, input);
    this.code = new CvvCode().deserialize(input.code);
    return this;
  }
}

export class CvvCode implements Deserializable {
  public name: string;
  public size: number;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export class Card implements Deserializable {
  public card: ValidCard;
  public isPotentiallyValid: boolean;
  public isValid: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.card = new ValidCard().deserialize(input.card);
    return this;
  }
}
