import { Component, OnInit } from "@angular/core";
import { Auth0Service } from "src/app/services/Auth/auth0.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit {
  profileJson: string = null;
  constructor(public auth: Auth0Service) {}

  ngOnInit() {
    this.auth.userProfile$.subscribe(
      profile => (this.profileJson = JSON.stringify(profile, null, 2))
    );
  }
}
